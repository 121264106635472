import React, { useEffect } from 'react'
import { useLocalization } from './LocalizationProvider'
import clsx from 'clsx'
import { IS_BROWSER, isAuthorizedAction } from '../../utils/utils'
import useDynamicStylesheets from '../hooks/useDynamicStylesheets'
import Loader from './Loader'

import '../legacy/lib/jquery'
import '../legacy/lib/jquery.tmpl'
import '../legacy/lib/jquery-ui'
import '../legacy/bcViewer/bcViewer'
import '../legacy/bcViewer/OO'
import '../legacy/bcViewer/SignalProcessing'
import '../legacy/bcViewer/Config'
import '../legacy/bcViewer/Utils'
import '../legacy/bcViewer/ViewerState'
import '../legacy/bcViewer/BaseDataFetcher'
import '../legacy/bcViewer/WebDataFetcher'
import '../legacy/bcViewer/StandaloneDataFetcher'
import '../legacy/bcViewer/ECGUnpacking'
import '../legacy/bcViewer/ArnikaDataFetcher'
import '../legacy/bcViewer/Canvases'
import '../legacy/bcViewer/bcViewerView'
import '../legacy/bcViewer/BaseViewer'
import '../legacy/bcViewer/BaseViewerView'
import '../legacy/bcViewer/BaseWavesViewer'
import '../legacy/bcViewer/BaseWavesViewerView'
import '../legacy/bcViewer/BaseWavesViewerModel'
import '../legacy/bcViewer/ECGViewer'
import '../legacy/bcViewer/ECGViewerView'
import '../legacy/bcViewer/ContextViewer'
import '../legacy/bcViewer/ContextViewerView'
import '../legacy/bcViewer/TimelineViewer'
import '../legacy/bcViewer/TimelineViewerView'
import '../legacy/bcViewer/HRViewer'
import '../legacy/bcViewer/HRViewerView'
import '../legacy/bcViewer/EventsViewer'
import '../legacy/bcViewer/EventsViewerView'
import '../legacy/bcViewer/EventsInfo'
import '../legacy/bcViewer/bcViewerApi'
import '../legacy/bcViewer/RemoteMonitorDataFetcher'
import io2 from '../legacy/bcViewer/socket.io.js'
import '../legacy/lib/jquery.notification'
import '../legacy/bcViewer/jquery.binarytransport'

if (IS_BROWSER) {
  window.io2 = io2
}

const DATE_FORMAT_CONVERTER_LEGACY = {
  DDMMYYYY: 'dd/MM/yyyy',
  MMDDYYYY: 'MM/dd/yyyy'
}

function getParameter(paramName) {
  const searchString = window.location.search.substring(1)
  let i
  let val
  const params = searchString.split('&')

  for (i = 0; i < params.length; i++) {
    val = params[i].split('=')
    if (val[0] === paramName) {
      return unescape(val[1])
    }
  }
  return null
}

const BCViewer = ({
  recordHash,
  recordUrl,
  recordType,
  studyType,
  hasEvents,
  config = {},
  zoom = 25,
  amplitude = 10,
  defaultQrsIntervals = 'rr',
  gridType = 10,
  displayPredictiveMeasurement = false,
  dateFormat = 'DDMMYYYY',
  measurements,
  userMarks,
  studyAuthorizedActions,
  isCaregiverVeterinarian,
  sessionId,
  callbacks
}) => {
  const isCSSLoaded = useDynamicStylesheets(
    [
      () =>
        import(
          /* webpackChunkName: "bc-viewer" */ '../legacy/bcViewer/stylesheets/main.css'
        ),
      () =>
        import(
          /* webpackChunkName: "bc-viewer" */ '../legacy/bcViewer/stylesheets/skin.css'
        ),
      () =>
        import(
          /* webpackChunkName: "bc-viewer" */ '../legacy/bcViewer/stylesheets/web.css'
        ),
      () =>
        import(
          /* webpackChunkName: "bc-viewer" */ '../legacy/bcViewer/stylesheets/jquery-ui.css'
        ),
      () =>
        import(
          /* webpackChunkName: "bc-viewer" */ '../legacy/bcViewer/stylesheets/jquery.notification.css'
        ),
      () =>
        import(
          /* webpackChunkName: "bc-viewer" */ '../legacy/bcViewer/stylesheets/dropdownmenu.css'
        )
    ],
    'bc-viewer',
    true
  )

  const { locale, interpolate } = useLocalization()

  useEffect(() => {
    if (!isCSSLoaded) return

    window.bcGlobals = window.bcGlobals || {}
    window.bcGlobals.locale = window.bcGlobals.locale || {}

    window.bcGlobals.locale.holterEvents = locale.bcviewer_holter_events
    window.bcGlobals.locale.restEvents = locale.bcviewer_rest_events
    window.bcGlobals.locale.physioEvents = locale.bcviewer_physio_events

    window.bcGlobals.locale.eventDurationSeconds =
      locale.bcviewer_event_duration_seconds
    window.bcGlobals.locale.eventDurationMinutes =
      locale.bcviewer_event_duration_minutes
    window.bcGlobals.locale.eventDurationHours =
      locale.bcviewer_event_duration_hours
    window.bcGlobals.locale.eventTimestamp = locale.bcviewer_event_timestamp
    window.bcGlobals.locale.eventHr = locale.bcviewer_event_hr
    window.bcGlobals.locale.eventTooltipTemplate =
      locale.bcviewer_event_tooltip_template
    window.bcGlobals.locale.channelAbbreviation =
      locale.bcviewer_channel_abbreviation
    window.bcGlobals.locale.dateFormat =
      DATE_FORMAT_CONVERTER_LEGACY[dateFormat]
    window.bcGlobals.locale.timeFormat = locale.bcviewer_time_format
    window.bcGlobals.locale.currentDetailsSeperator =
      locale.bcviewer_current_details_seperator
    window.bcGlobals.locale.timeSpanFromStart =
      locale.bcviewer_time_span_from_start
    window.bcGlobals.locale.timeSpanFormat = locale.bcviewer_time_span_format
    window.bcGlobals.locale.currentViewZoom = locale.bcviewer_current_view_zoom
    window.bcGlobals.locale.currentViewAmpl = locale.bcviewer_current_view_ampl
    window.bcGlobals.locale.measurementResult =
      locale.bcviewer_measurement_result
    window.bcGlobals.locale.measurementHRResult =
      locale.bcviewer_measurement_hr_result
    window.bcGlobals.locale.birthdate_format =
      DATE_FORMAT_CONVERTER_LEGACY[dateFormat]
    window.bcGlobals.locale.hrResult = locale.bcviewer_realtime_hr
    window.bcGlobals.locale.remoteWaiting = locale.bcviewer_remote_waiting
    window.bcGlobals.locale.remoteError = locale.bcviewer_remote_error

    const _config = new window.bcViewer.Config(config)
    _config.eventsViewer.HIDDEN = studyType === 1 || !hasEvents
    _config.HRViewer.HIDDEN = studyType === 1 || !hasEvents
    _config.ecgGrid.SMALL_BLOCKS_PER_BIG_BLOCK = gridType
    _config.ecgViewer.MEASUREMENT.DISPLAY_PREDICTIVE_MEASUREMENT =
      displayPredictiveMeasurement

    if (!recordHash && !recordUrl) {
      _config.contextViewer.HIDDEN = true
    }

    window.bcGlobals.reportMode = getParameter('reportMode')
    window.bcGlobals.viewerId = getParameter('viewerId')
    window.bcGlobals.showScale = getParameter('showScale')
    window.bcGlobals.showAnnotations = getParameter('showAnnotations')
    const fullscreenMode = getParameter('fullscreenMode')
    const fullDisclosureViewer = getParameter('fullDisclosureViewer')

    window.bcGlobals.isVeterinarian = isCaregiverVeterinarian

    // vetMode is for when we dont want to use the logged in user type - in veecardia.com/veterinary-ecg-library for example
    /* var vetMode = getParameter('vetMode');
    if (vetMode !== null) {
        bcGlobals.isVeterinarian = (vetMode === "true");
    } */

    if (fullDisclosureViewer == 'true') {
      _config.ecgViewer.CACHE_WINDOW_SIZE = 1
    }

    if (window.bcGlobals.reportMode == 'true') {
      _config.ecgViewer.HIDE_HEADER = true
      _config.contextViewer.HIDDEN = true
      _config.timelineViewer.HIDDEN = true
      _config.controlPanel.HIDDEN = true
    }

    /* uncomment when remote is supported
    if (remoteMonitor) {
      _config.timelineViewer.HIDDEN = true
    }*/

    const BCViewer = window.bcViewer
    // eslint-disable-next-line no-new
    window._bcViewer = new BCViewer(
      {
        recordHash,
        recordURL: recordUrl,
        recordType,
        studyType, // 1 - rest, 2 - holter
        fullDisclosureViewer,
        remoteMonitor: typeof sessionId !== 'undefined',
        sessionId,
        defaults: {
          zoom,
          amplitude,
          qrs_intervals: defaultQrsIntervals
        },
        fullscreenMode: fullscreenMode,
        doctorStudyEdits: {
          measurement_values:
            (measurements && measurements.measurement_values) || '{}',
          userMarks: userMarks || []
        },
        callbacks: callbacks
      },
      _config
    )
  }, [isCSSLoaded])

  if (!isCSSLoaded) return <Loader />

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          /* eslint-disable no-template-curly-in-string */
          __html:
            "<script id='bcv_dropdown_option' type='text/x-jquery-tmpl'><option value='${value}'>${text}</option></script>" +
            "<script id='bcv_current_info_with_date' type='text/x-jquery-tmpl'><span>${date} ${currentDetailsSeperator} ${timeSpan} ${currentDetailsSeperator} ${zoom} ${ampl}</span></script>" +
            "<script id='bcv_current_info_no_date' type='text/x-jquery-tmpl'><span>${timeSpanFromStart} ${currentDetailsSeperator} ${zoom} ${ampl}</span></script>" +
            "<script id='bcv_measurement_save_result_list' type='text/x-jquery-tmpl'> " +
            '<ul>' +
            '<li>' +
            locale.bcviewer_measurement_save.result_text.HR +
            '</li>' +
            ' <li>' +
            locale.bcviewer_measurement_save.result_text.P +
            '</li>' +
            ' <li>' +
            locale.bcviewer_measurement_save.result_text.P_MV +
            '</li>' +
            '  <li>' +
            locale.bcviewer_measurement_save.result_text.PR +
            '</li>' +
            '<li>' +
            locale.bcviewer_measurement_save.result_text.QRS +
            '</li>' +
            '<li>' +
            locale.bcviewer_measurement_save.result_text.R +
            '</li>' +
            '<li>' +
            locale.bcviewer_measurement_save.result_text.QT +
            '</li>' +
            '</ul>' +
            '</script>'
          /* eslint-enable no-template-curly-in-string */
        }}
      />

      <div
        id='bc_viewer'
        className={clsx('disable_selection use_dropdownmenu ', {
          enable_measurement_save: isAuthorizedAction(
            studyAuthorizedActions,
            'Study:diagnosis'
          )
        })}
      >
        <div className='bcv_top'>
          <div className='bcv_events_container'>
            <div className='bcv_events_viewer_headers'>
              <div className='bcv_timeline_header bcv_events_viewer_header' />
              <div className='bcv_hr_header bcv_events_viewer_header'>
                <div>{locale.bcviewer_hr}</div>
              </div>
              <div className='bcv_events_header bcv_events_viewer_header'>
                <select className='bcv_events_list' />
                <button
                  className='bcv_event_button bcv_event_prev bcv_sprite'
                  title={locale.bcviewer_prev_event}
                />
                <button
                  className='bcv_event_button bcv_event_next bcv_sprite'
                  title={locale.bcviewer_next_event}
                />
              </div>
            </div>
            <div className='bcv_event_viewer_canvases'>
              <div className='bcv_time_indicator' />
              <canvas className='bcv_timeline_canvas' />
              <canvas className='bcv_hr_canvas' />
              <canvas className='bcv_events_canvas' />
            </div>
          </div>
          <div className='bcv_current_view_info' />
          <div className='bcv_measurement_result' />
          <span className='bcv_updating_notification'>
            {locale.bcviewer_updating_notification}
          </span>
        </div>
        <div className='bcv_ecg_viewer'>
          <div className='bcv_ecg_control_panel'>
            <div className='bcv_controls_group bcv_zoom_x_controls'>
              <span className='bcv_controls_title'>{locale.bcviewer_zoom}</span>
              <button className='bcv_zoom_x bcv_control_button' value='5'>
                5
              </button>
              <button className='bcv_zoom_x bcv_control_button' value='10'>
                10
              </button>
              <button className='bcv_zoom_x bcv_control_button' value='25'>
                25
              </button>
              <button className='bcv_zoom_x bcv_control_button' value='50'>
                50
              </button>
              <button className='bcv_zoom_x bcv_control_button' value='100'>
                100
              </button>
              <span className='bcv_controls_units'>
                {locale.bcviewer_mm_sec}
              </span>
            </div>
            <div className='bcv_controls_group bcv_zoom_y_controls'>
              <span className='bcv_controls_title'>{locale.bcviewer_ampl}</span>
              <button className='bcv_zoom_y bcv_control_button' value='2'>
                2
              </button>
              <button className='bcv_zoom_y bcv_control_button' value='5'>
                5
              </button>
              <button className='bcv_zoom_y bcv_control_button' value='10'>
                10
              </button>
              <button className='bcv_zoom_y bcv_control_button' value='20'>
                20
              </button>
              <button className='bcv_zoom_y bcv_control_button' value='40'>
                40
              </button>
              <span className='bcv_controls_units'>
                {locale.bcviewer_mm_mv}
              </span>
            </div>
            {/* <div className='bcv_channel_expansion'>
              <input type='checkbox'>{i18n.bcviewer_show_7_leads}</input>
            </div>
            <div className='bcv_channel_expansion_12'>
              <input type='checkbox'>{i18n.bcviewer_show_12_leads}</input>
            </div> */}
            <div className='bcv_filters_group bcv_clearfix'>
              <button
                className='bcv_magnify_button bcv_sprite'
                title={locale.bcviewer_magnify}
              />
              <button
                className='bcv_decrease_spacing bcv_sprite'
                title={locale.bcviewer_decrease_spacing}
              />
              <button
                className='bcv_increase_spacing bcv_sprite'
                title={locale.bcviewer_increase_spacing}
              />
              <button
                className='bcv_measure_button bcv_sprite'
                title={locale.bcviewer_measure}
              />
              <button
                className='bcv_baseline_correction_button bcv_sprite'
                title={locale.bcviewer_baseline_correction}
              />
              <button
                className='bcv_smooth_button bcv_sprite'
                title={locale.bcviewer_smooth}
              />
              <button
                className='bcv_fullscreen_button bcv_sprite'
                title={locale.bcviewer_fullscreen}
                data-exit-fullscreen-title={locale.bcviewer_exit_fullscreen}
              />
              <div className='bcv_dropdownmenu'>
                <button className='bcv_menu_button bcv_sprite'></button>
                <ul className='bcv_dropdownmenu_content'>
                  <li id='bcv_dropdownmenu_filters'>
                    <div>{locale.bcviewer_filters}</div>
                    <ul>
                      <li data-action='smooth'>
                        <div>
                          <span className='ui-icon ui-icon-check'></span>
                          <span>{locale.bcviewer_smooth}</span>
                        </div>
                      </li>
                      <li data-action='baseline_correction'>
                        <div>
                          <span className='ui-icon ui-icon-check'></span>
                          <span>{locale.bcviewer_baseline_correction}</span>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li id='bcv_dropdownmenu_qrs_marks'>
                    <div>{locale.bcviewer_qrs_intervals}</div>
                    <ul>
                      <li data-action='qrs_marks' data-value='hr'>
                        <div>
                          <span className='ui-icon ui-icon-check'></span>
                          <span>{locale.bcviewer_qrs_intervals_hr}</span>
                        </div>
                      </li>
                      <li data-action='qrs_marks' data-value='rr'>
                        <div>
                          <span className='ui-icon ui-icon-check'></span>
                          <span>{locale.bcviewer_qrs_intervals_rr}</span>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li id='bcv_dropdownmenu_zoomX'>
                    <div>{locale.bcviewer_paper_speed}</div>
                    <ul>
                      <li data-action='zoomX' data-value='5'>
                        <div>
                          <span className='ui-icon ui-icon-check'></span>
                          <span>
                            {interpolate(locale.bcviewer_page_speed_value, {
                              speed: 5
                            })}
                          </span>
                        </div>
                      </li>
                      <li data-action='zoomX' data-value='10'>
                        <div>
                          <span className='ui-icon ui-icon-check'></span>
                          <span>
                            {interpolate(locale.bcviewer_page_speed_value, {
                              speed: 10
                            })}
                          </span>
                        </div>
                      </li>
                      <li data-action='zoomX' data-value='25'>
                        <div>
                          <span className='ui-icon ui-icon-check'></span>
                          <span>
                            {interpolate(locale.bcviewer_page_speed_value, {
                              speed: 25
                            })}
                          </span>
                        </div>
                      </li>
                      <li data-action='zoomX' data-value='50'>
                        <div>
                          <span className='ui-icon ui-icon-check'></span>
                          <span>
                            {interpolate(locale.bcviewer_page_speed_value, {
                              speed: 50
                            })}
                          </span>
                        </div>
                      </li>
                      <li data-action='zoomX' data-value='100'>
                        <div>
                          <span className='ui-icon ui-icon-check'></span>
                          <span>
                            {interpolate(locale.bcviewer_page_speed_value, {
                              speed: 100
                            })}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li id='bcv_dropdownmenu_zoomY'>
                    <div>{locale.bcviewer_amplitude}</div>
                    <ul>
                      <li data-action='zoomY' data-value='2'>
                        <div>
                          <span className='ui-icon ui-icon-check'></span>
                          <span>
                            {interpolate(locale.bcviewer_ampl_value, {
                              ampl: 2
                            })}
                          </span>
                        </div>
                      </li>
                      <li data-action='zoomY' data-value='5'>
                        <div>
                          <span className='ui-icon ui-icon-check'></span>
                          <span>
                            {interpolate(locale.bcviewer_ampl_value, {
                              ampl: 5
                            })}
                          </span>
                        </div>
                      </li>
                      <li data-action='zoomY' data-value='10'>
                        <div>
                          <span className='ui-icon ui-icon-check'></span>
                          <span>{locale.bcviewer_ampl_value_10}</span>
                        </div>
                      </li>
                      <li data-action='zoomY' data-value='20'>
                        <div>
                          <span className='ui-icon ui-icon-check'></span>
                          <span>
                            {interpolate(locale.bcviewer_ampl_value, {
                              ampl: 20
                            })}
                          </span>
                        </div>
                      </li>
                      <li data-action='zoomY' data-value='40'>
                        <div>
                          <span className='ui-icon ui-icon-check'></span>
                          <span>
                            {interpolate(locale.bcviewer_ampl_value, {
                              ampl: 40
                            })}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li id='bcv_dropdownmenu_show_hide_leads'>
                    <div>{locale.bcviewer_show_hide_leads}</div>
                    <ul></ul>
                  </li>
                  <li>-</li>
                  <li
                    className='bcv_dropdownmenu_measurement'
                    data-action='measurement'
                    data-value='in'
                  >
                    <div>
                      <span className='ui-icon ui-icon-check'></span>
                      <span>{locale.bcviewer_digital_calipers_mode}</span>
                    </div>
                  </li>
                  <li
                    className='bcv_dropdownmenu_user_marks'
                    data-action='user_marks'
                  >
                    <div>
                      <span className='ui-icon ui-icon-check'></span>
                      <span>{locale.bcviewer_user_marks.add_mark}</span>
                    </div>
                  </li>
                  <li className='bcv_dropdownmenu_zoom_separator bcv_dropdownmenu_not_in_fullscreen'>
                    -
                  </li>
                  <li
                    className='bcv_dropdownmenu_not_in_fullscreen bcv_dropdownmenu_zoom_in'
                    data-action='zoom_in_out'
                    data-value='in'
                  >
                    <div>{locale.bcviewer_zoom_in}</div>
                  </li>
                  <li
                    className='bcv_dropdownmenu_not_in_fullscreen bcv_dropdownmenu_zoom_out'
                    data-action='zoom_in_out'
                    data-value='out'
                  >
                    <div>{locale.bcviewer_zoom_out}</div>
                  </li>
                  <li className='bcv_dropdownmenu_not_in_fullscreen'>-</li>
                  <li
                    className='bcv_dropdownmenu_not_in_fullscreen bcv_dropdownmenu_increase_spacing'
                    data-action='channels_spacing'
                    data-value='1'
                  >
                    <div>{locale.bcviewer_increase_spacing}</div>
                  </li>
                  <li
                    className='bcv_dropdownmenu_not_in_fullscreen bcv_dropdownmenu_decrease_spacing'
                    data-action='channels_spacing'
                    data-value='-1'
                  >
                    <div>{locale.bcviewer_decrease_spacing}</div>
                  </li>
                  <li className='bcv_dropdownmenu_not_in_fullscreen bcv_dropdownmenu_fullscreen_separator'>
                    -
                  </li>
                  <li
                    data-action='fullscreen'
                    className='bcv_dropdownmenu_fullscreen'
                  >
                    <div id='bcv_dropdownmenu_enter_fullscreen'>
                      {locale.bcviewer_enter_fullscreen}
                    </div>
                    <div id='bcv_dropdownmenu_exit_fullscreen'>
                      {locale.bcviewer_exit_fullscreen}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='bcv_canvas_wrapper'>
            <div className='bcv_loading_notification' />
            <canvas className='bcv_main_canvas' />
            <div className='bcv_realtime_heartrate' />
            <div className='bcv_realtime_leadnames_container' />
            <div className='bcv_measurement_save_result'></div>
            <div className='bcv_user_mark_edit_panel'>
              <textarea
                id='bcv_user_mark_comment'
                placeholder={locale.bcviewer_user_marks.add_description}
                rows='5'
                cols='50'
              ></textarea>
              <div>
                <button id='bcv_user_mark_remove'>
                  {locale.bcviewer_user_marks.remove_mark}
                </button>
                <button id='bcv_user_mark_save'>
                  {locale.bcviewer_user_marks.save_mark}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='bcv_context_viewer'>
          <select className='bcv_channels_list' />
          <div className='bcv_canvas_wrapper'>
            <canvas className='bcv_context_canvas' />
            <div className='bcv_context_highlight' />
            <div className='bcv_context_highlight_on_hover' />
          </div>
        </div>
        <div className='bcv_measurement_save disable_selection'>
          {locale.bcviewer_measurement_save.save_as}
          <select defaultValue='default'>
            <option disabled value='default'></option>
            <option value='HR'>{locale.bcviewer_measurement_save.HR}</option>
            <option value='P'>{locale.bcviewer_measurement_save.P}</option>
            <option value='PR'>{locale.bcviewer_measurement_save.PR}</option>
            <option value='QRS'>{locale.bcviewer_measurement_save.QRS}</option>
            <option value='R'>{locale.bcviewer_measurement_save.R}</option>
            <option value='QT'>{locale.bcviewer_measurement_save.QT}</option>
          </select>
        </div>
      </div>
    </>
  )
}

export default BCViewer
