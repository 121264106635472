import React, { useState, useEffect, useContext } from 'react'
import {
  gql,
  useQuery,
  useLazyQuery,
  useApolloClient,
  useMutation
} from '@apollo/client'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import AWS from 'aws-sdk/global'
import clsx from 'clsx'
import { Link, navigate } from '@reach/router' // eslint-disable-line import/no-unresolved
import { currentCredentials } from '../../utils/credentials'
import AuthContext from './AuthContext'
import BCViewer from './BCViewer'
import BCStethoscopeViewer from './BCStethoscopeViewer'
import BCSpiroViewer from './BCSpiroViewer'
import Questionnaire from './Questionnaire'
import PatientDetails from './PatientDetails'
import StudyAttachments from './StudyAttachments'
import StudyComments from './StudyComments'
import StudySummary from './StudySummary'
import CreateStudyShare from './CreateStudyShare'
import CreateStudyCopy from './CreateStudyCopy'
import StudyShares from './StudyShares'
import Notification from './Notification'
import StudyAudits from './StudyAudits'
import StudyLog from './StudyLog'
// import StudyReports from './StudyReports'
// import StudyReferrals from './StudyReferrals'
import Modal from './Modal'
import DiagnosisReferralForm from './DiagnosisReferralForm'
import ReferralCancel from './ReferralCancel'
import Loader from './Loader'
import {
  GET_STUDY_DATA,
  GET_STUDY_AUTHORIZED_ACTIONS,
  GET_API_KEY,
  GET_PRIVATE_REPORT_PDF,
  GET_PUBLIC_REPORT_PDF,
  GET_STUDY_AUDITS,
  GET_STUDY_LOG,
  GET_SERVICE_PLAN_CHECKOUT_PAGE,
  GET_INVOICE_PAGE
} from './queries'
import Layout from './Layout'
import {
  base64UrlEncode,
  durationToHuman,
  PAPER_SPEED_CONVERTER,
  AMPLITUTE_CONVERTER,
  QRS_INTERVALS_CONVERTER,
  GRID_TYPE_CONVERTER,
  DATE_FORMAT_CONVERTER,
  WEIGHT_CONVERTER,
  HEIGHT_CONVERTER,
  RECORDS_PRIORITIES,
  REFERRAL_TYPES_CONVERTER,
  REFERRAL_TYPES_CONVERTER_FROM_LEGACY,
  isAuthorizedAction,
  UPLOAD_STATUS,
  getParams,
  ANIMAL_TYPE_NEW_TO_LEGACY_STRING,
  getAgeUnitsLocalizationKey,
  adjustTimeToTimezone,
  localizePatientName,
  ANONYMOUS_USER_ID
} from '../../utils/utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import DiagnosisTab from './DiagnosisTab'
import { useLocalization } from './LocalizationProvider'
import AuthScenery from './Auth/AuthScenery'

import '../legacy/stylesheets/ConclusionTemplateSelect.css'

const callbacks = {
  onMeasurementValuesChange: null
}

const CREATE_MEASUREMENTS = gql`
  mutation CREATE_MEASUREMENTS(
    $studyId: ID!
    $caregiver: CaregiverConnectionInput!
    $clinic: ClinicConnectionInput!
    $input: StudyMeasurementsCreateInput!
  ) {
    studyMeasurementsCreate(
      studyId: $studyId
      caregiver: $caregiver
      clinic: $clinic
      input: $input
    ) {
      id
    }
  }
`

const UPDATE_MEASUREMENTS = gql`
  mutation UPDATE_MEASUREMENTS($input: StudyMeasurementsUpdateInput!) {
    studyMeasurementsUpdate(input: $input) {
      id
    }
  }
`

const CREATE_USER_MARKS = gql`
  mutation CREATE_USER_MARKS(
    $studyId: ID!
    $caregiver: CaregiverConnectionInput!
    $clinic: ClinicConnectionInput!
    $input: StudyUserMarksCreateInput!
  ) {
    studyUserMarksCreate(
      studyId: $studyId
      caregiver: $caregiver
      clinic: $clinic
      input: $input
    ) {
      id
    }
  }
`

const UPDATE_USER_MARKS = gql`
  mutation UPDATE_USER_MARKS($input: StudyUserMarksUpdateInput!) {
    studyUserMarksUpdate(input: $input) {
      id
    }
  }
`
const MARK_STUDY_AS_SEEN = gql`
  mutation MARK_STUDY_AS_SEEN($studyId: ID!, $clinicId: ID!) {
    studyMarkAsSeen(studyId: $studyId, clinicId: $clinicId) {
      id
    }
  }
`

const Study = (props) => {
  const queryParams = getParams(props.location.search)
  const { locale, interpolate } = useLocalization()
  const [isMenuOpen, toggleMenu] = useState(false)
  const [tab, setTab] = useState(null)
  const [notification, setNotification] = useState(
    queryParams.redirectFromPayments === '1'
      ? {
          isSuccess: true,
          message: locale.payments.reports.success
        }
      : queryParams.redirectFromPaymentsFailure === '1'
      ? {
          isError: true,
          message: locale.payments.reports.error
        }
      : null
  )
  // the rerender hack is to force rerender from children components after fetching data from server to be stored as cache
  const [, forceReRender] = useState(null)
  const [isShareMode, setShareMode] = useState(false)
  const [isCopyMode, setCopyMode] = useState(false)
  const [isShowSharesMode, setShowSharesMode] = useState(false)
  const [isEventsTabActive, setEventsTabToActive] = useState(false)
  const [signedReportUrl, setSignedReportUrl] = useState(null)
  const [isInDiagnosticReferralMode, setIsInDiagnosticReferralMode] =
    useState(false)
  const [referralToCancel, setReferralToCancel] = useState(null)
  const [isFetchingInvoide, setIsFetchingInvoide] = useState(false)
  const [isInvoicePageNotFound, setIsInvoicePageNotFound] = useState(false)
  const [initialMEA, setInitialMEA] = useState(null)
  const [currentMeasurementsId, setCurrentMeasurementsId] = useState(null)
  const [currentMeasurements, setCurrentMeasurements] = useState(null)
  const [currentUserMarksId, setCurrentUserMarksId] = useState(null)
  const [arnikaIFrameUrl, setArnikaIFrameUrl] = useState(null)
  const [hasCardiobirdReport, setHasCardiobirdReport] = useState(false)
  const currentUser = useContext(AuthContext)
  const isAnonymous = currentUser.id === ANONYMOUS_USER_ID

  const [createMeasurements] = useMutation(CREATE_MEASUREMENTS, {
    onCompleted: (data) => {
      setCurrentMeasurementsId(data.studyMeasurementsCreate.id)
    }
  })
  const [updateMeasurements] = useMutation(UPDATE_MEASUREMENTS)
  const [createUserMarks] = useMutation(CREATE_USER_MARKS, {
    onCompleted: (data) => {
      setCurrentUserMarksId(data.studyUserMarksCreate.id)
    }
  })
  const [updateUserMarks] = useMutation(UPDATE_USER_MARKS)

  const {
    loading,
    error,
    data: _data
  } = useQuery(GET_STUDY_DATA, {
    variables: {
      id: props.id,
      clinicId: currentUser.clinic.id,
      referralsConfigId: currentUser.group,
      hasGroup: !!currentUser.group
    }
  })

  const [getStudyAuthrorizedActions, { data: getStudyAuthrorizedActionsData }] =
    useLazyQuery(GET_STUDY_AUTHORIZED_ACTIONS, {
      fetchPolicy: 'network-only',
      variables: {
        id: props.id
      }
    })

  const servicePlanBS = currentUser.clinic.servicePlans.find(
    (sp) => sp.provider === 'Bluesnap'
  )
  const servicePlanId = servicePlanBS
    ? servicePlanBS.id
    : currentUser.clinic.servicePlans[0].id

  const [
    getServicePlanCheckoutPage,
    { loading: loadingServicePlanCheckoutPage }
  ] = useLazyQuery(GET_SERVICE_PLAN_CHECKOUT_PAGE, {
    variables: {
      id: servicePlanId,
      clinicId: currentUser.clinic.id,
      returnUrl: window.location.origin + window.location.pathname
    }
  })

  const [isViewerReadyToPrint, setIsViewerReadyToPrint] = useState(false)

  const client = useApolloClient()

  const STUDY_AUDITS_FIRST = 5
  const {
    loading: loadingStudyAudits,
    data: studyAuditsData,
    fetchMore: studyAuditsFetchMore
  } = useQuery(GET_STUDY_AUDITS, {
    variables: {
      id: props.id,
      first: STUDY_AUDITS_FIRST
    },
    skip:
      _data && _data.study
        ? !isAuthorizedAction(
            _data.study.authorizedActions,
            'Study:auditStream'
          )
        : true
  })

  const STUDY_LOG_FIRST = 5
  const {
    loading: loadingStudyLog,
    data: studyLogData,
    fetchMore: studyLogFetchMore
  } = useQuery(GET_STUDY_LOG, {
    variables: {
      id: props.id,
      first: STUDY_LOG_FIRST
    },
    skip:
      _data && _data.study
        ? !isAuthorizedAction(_data.study.authorizedActions, 'Study:logStream')
        : true
  })

  const RECORD_TYPE_TO_TAB_TITLE = {
    RecordEcgRestData: locale.studies.types.rest,
    RecordEcgHolterData: locale.studies.types.holter,
    RecordStethoscopeData: locale.studies.types.stethoscope,
    RecordSpirometerData: locale.studies.types.spirometer,
    RecordQuestionnaireData: locale.studies.types.questionnaire,
    RecordAttachmentData: locale.studies.types.attachment
  }

  // initiate local state - since its SPA so when navigating to other studies from within this one it leaves local state as is which is wrong since state of one study doesn't always the same as in other study
  useEffect(() => {
    setTab(null)
    setEventsTabToActive(false)
    setInitialMEA(null)
    setCurrentMeasurementsId(null)
    setCurrentMeasurements(null)
  }, [props.id])

  useEffect(() => {
    document.body.classList.add('has-navbar-sub-navbar-fixed-top')
    return () => {
      document.body.classList.remove('has-navbar-sub-navbar-fixed-top')
    }
  }, [])

  useEffect(() => {
    let hasMeasurements, studyMeasurements
    if (_data && _data.study) {
      hasMeasurements = !!_data.study.measurements
      studyMeasurements = hasMeasurements ? _data.study.measurements : {}
    }
    callbacks.onMeasurementValuesChange = (studyId, measurements) => {
      setCurrentMeasurements({ measurements: JSON.stringify(measurements) })
      if (hasMeasurements || currentMeasurementsId) {
        updateMeasurements({
          variables: {
            input: {
              id: studyMeasurements.id || currentMeasurementsId,
              updatedAt: new Date().toISOString(),
              measurements: JSON.stringify(measurements)
            }
          },
          update(cache) {
            cache.modify({
              id: cache.identify(_data.study),
              fields: {
                measurements: (existingMeasurements) => {
                  return {
                    ...existingMeasurements,
                    measurements: JSON.stringify(measurements)
                  }
                }
              }
            })
          }
        })
      } else {
        const id = uuidv4()
        const createdAt = new Date().toISOString()
        createMeasurements({
          variables: {
            studyId,
            caregiver: {
              id: currentUser.id,
              name: currentUser.settings.displayName,
              email: currentUser.email
            },
            clinic: {
              id: currentUser.clinic.id,
              name: currentUser.clinic.name
            },
            input: {
              id,
              createdAt,
              measurements: JSON.stringify(measurements)
            }
          },
          update(cache) {
            cache.modify({
              id: cache.identify(_data.study),
              fields: {
                measurements: () => {
                  return {
                    __typename: 'MeasurementsData',
                    id,
                    createdAt,
                    measurements: JSON.stringify(measurements),
                    caregiver: {
                      __typename: 'CaregiverConnectionLink',
                      id: currentUser.id,
                      name: currentUser.settings.displayName,
                      email: currentUser.email
                    },
                    clinic: {
                      __typename: 'ClinicConnectionLink',
                      id: currentUser.clinic.id,
                      name: currentUser.clinic.name
                    }
                  }
                }
              }
            })
          }
        })
      }
    }
  }, [_data, currentMeasurementsId])

  useEffect(() => {
    let hasUserMarks, studyUserMarks
    if (_data && _data.study) {
      hasUserMarks = !!_data.study.userMarks
      studyUserMarks = hasUserMarks ? _data.study.userMarks : {}
    }
    callbacks.onUserMarksChange = (studyId, userMarks) => {
      if (hasUserMarks || currentUserMarksId) {
        updateUserMarks({
          variables: {
            input: {
              id: studyUserMarks.id || currentUserMarksId,
              updatedAt: new Date().toISOString(),
              userMarks: JSON.stringify(userMarks)
            }
          },
          update(cache) {
            cache.modify({
              id: cache.identify(_data.study),
              fields: {
                userMarks: (existingUserMarks) => {
                  return {
                    ...existingUserMarks,
                    userMarks: JSON.stringify(userMarks)
                  }
                }
              }
            })
          }
        })
      } else {
        const id = uuidv4()
        const createdAt = new Date().toISOString()
        createUserMarks({
          variables: {
            studyId,
            caregiver: {
              id: currentUser.id,
              name: currentUser.settings.displayName,
              email: currentUser.email
            },
            clinic: {
              id: currentUser.clinic.id,
              name: currentUser.clinic.name
            },
            input: {
              id,
              createdAt,
              userMarks: JSON.stringify(userMarks)
            }
          },
          update(cache) {
            cache.modify({
              id: cache.identify(_data.study),
              fields: {
                userMarks: () => {
                  return {
                    __typename: 'UserMarksData',
                    id,
                    createdAt,
                    userMarks: JSON.stringify(userMarks),
                    caregiver: {
                      __typename: 'CaregiverConnectionLink',
                      id: currentUser.id,
                      name: currentUser.settings.displayName,
                      email: currentUser.email
                    },
                    clinic: {
                      __typename: 'ClinicConnectionLink',
                      id: currentUser.clinic.id,
                      name: currentUser.clinic.name
                    }
                  }
                }
              }
            })
          }
        })
      }
    }
  }, [_data, currentUserMarksId])

  useEffect(() => {
    if (
      _data &&
      _data.study &&
      queryParams.paymentRequest === '1' &&
      (_data.study.referrals.length === 0 ||
        _data.study.referrals[0].status === 'PaymentPending')
    ) {
      const referral =
        data.study.referrals.length > 0 ? data.study.referrals[0] : null
      const targetCaregiverId = referral
        ? referral.targetQueue
          ? referral.targetQueue.id
          : referral.targetCaregiver.id
        : queryParams.targetCaregiverId
      const referralType = referral
        ? referral.referralType
        : queryParams.referralType
      const sourceCaregiver = !_data.study.referralsConfig
        ? []
        : _data.study.referralsConfig.reduce(
            (selected, current) =>
              selected ||
              (current.caregiver.id === targetCaregiverId &&
              current.referralType ===
                (REFERRAL_TYPES_CONVERTER[
                  referralType
                ] /* new app format of referralType */
                  ? referralType
                  : REFERRAL_TYPES_CONVERTER_FROM_LEGACY[
                      referralType
                    ]) /* legacy app format of referralType which was redirected from legacy app */
                ? current
                : null),
            null
          )
      if (sourceCaregiver) {
        setIsInDiagnosticReferralMode(true)
      }
    }
  }, [_data])
  useEffect(() => {
    async function callMe() {
      if (!_data || !_data.study) return
      const hasCardiobirdReport = !!_data.study.referrals.find(
        (referral) =>
          referral.referralType === 'ReferralAutomaticAnalysis' &&
          referral.status === 'Done'
      )
      setHasCardiobirdReport(hasCardiobirdReport)
      if (
        (_data.study.protocol === 'Holter' && _data.study.hasReport) ||
        hasCardiobirdReport
      ) {
        const {
          data: {
            report: { signedPrivatePdf }
          }
        } = await client.query({
          query: GET_PRIVATE_REPORT_PDF,
          fetchPolicy: 'network-only',
          variables: {
            reportId: _data.study.reports[0].id
          }
        })
        if (signedPrivatePdf) {
          setSignedReportUrl(signedPrivatePdf)
        } else if (_data.study.protocol === 'Holter' && _data.study.hasReport) {
          const {
            data: {
              report: { signedPublicPdf }
            }
          } = await client.query({
            query: GET_PUBLIC_REPORT_PDF,
            fetchPolicy: 'network-only',
            variables: {
              reportId: _data.study.reports[0].id
            }
          })
          if (signedPublicPdf) {
            setSignedReportUrl(signedPublicPdf)
          }
        }
      }
    }
    callMe()
  }, [_data])
  useEffect(() => {
    async function checkIfAnalyzeEcgEnabled() {
      if (
        !_data ||
        !_data.study ||
        _data.study.protocol !== 'Holter' ||
        !isAuthorizedAction(
          _data.study.authorizedActions,
          'Study:analyzeEcg'
        ) ||
        isAuthorizedAction(
          _data.study.authorizedActions,
          'Study:analyzeEcgEnable'
        )
      ) {
        return
      }
      await new Promise((resolve) => {
        setTimeout(resolve, 30000)
      })
      await getStudyAuthrorizedActions()
    }
    checkIfAnalyzeEcgEnabled()
  }, [_data, getStudyAuthrorizedActionsData])

  useEffect(() => {
    async function callMe() {
      if (!_data || !_data.study || _data.study.isSeen) return
      await client.mutate({
        mutation: MARK_STUDY_AS_SEEN,
        variables: {
          studyId: _data.study.id,
          clinicId: currentUser.clinic.id
        },
        update(cache) {
          cache.modify({
            id: cache.identify(_data.study),
            fields: {
              isSeen: () => true
            }
          })
          cache.modify({
            id: cache.identify({
              __typename: 'StudyShareDataInMyStudies',
              studyId: _data.study.id
            }),
            fields: {
              isSeen: () => true
            }
          })
        }
      })
    }
    callMe()
  }, [_data])

  useEffect(() => {
    if (
      _data &&
      _data.study &&
      _data.study.protocol !== 'Rest' &&
      _data.study.protocol !== 'Holter'
    ) {
      setIsViewerReadyToPrint(true)
    } else {
      const onViewerReadyToPrint = () => {
        setIsViewerReadyToPrint(true)
        window.removeEventListener('error', onViewerReadyToPrint)
      }

      window.addEventListener('ViewerReadyToPrintVanilla', onViewerReadyToPrint)
      window.addEventListener('error', onViewerReadyToPrint)

      return () => {
        window.removeEventListener(
          'ViewerReadyToPrintVanilla',
          onViewerReadyToPrint
        )

        window.removeEventListener('error', onViewerReadyToPrint)
      }
    }
  }, [_data])

  if (loading)
    return (
      <Layout gotoLoginPage={props.gotoLoginPage}>
        <Notification
          notification={notification}
          onClose={() => setNotification(null)}
        />
        <Loader />
      </Layout>
    )
  if (error) {
    return (
      <AuthScenery>
        <Modal standalone={false}>
          {error.message === 'AccessDenied' ? (
            <>
              <label className='label is-medium mb-0'>
                {locale.access_denied_line1}
              </label>
              <label className='label is-medium'>
                {locale.access_denied_line2}
              </label>
              <div className='has-text-right'>
                <button
                  id='sign-in'
                  className='button is-primary'
                  onClick={() =>
                    isAnonymous ? props.gotoLoginPage() : navigate('/')
                  }
                >
                  {isAnonymous
                    ? locale.profile.login
                    : locale.profile.my_studies}
                </button>
              </div>
            </>
          ) : (
            <p>{locale.something_wrong}</p>
          )}
        </Modal>
      </AuthScenery>
    )
  }
  if (!_data || !_data.study) {
    return (
      <AuthScenery>
        <Modal standalone={false}>
          <p className='has-text-centered has-text-weight-bold mb-4'>
            {locale.study_not_found}
          </p>
          <p className='has-text-centered'>
            <Link to='/' className='button is-primary is-outlined'>
              {locale.back_to_studies}
            </Link>
          </p>
        </Modal>
      </AuthScenery>
    )
  }
  const data = {
    ..._data,
    study: {
      ..._data.study,
      records: [..._data.study.records]
        .sort(
          (e1, e2) =>
            RECORDS_PRIORITIES[e1.__typename] -
            RECORDS_PRIORITIES[e2.__typename]
        )
        .filter((record) => record.__typename !== 'RecordAttachmentData'),
      referralsConfig: _data.study.referralsConfig || []
    }
  }
  const _attachmentGroups = {}
  const attachments = _data.study.records.filter(
    (record) => record.__typename === 'RecordAttachmentData'
  )
  attachments.forEach(({ group, ...rest }) => {
    if (!_attachmentGroups[group.id]) {
      _attachmentGroups[group.id] = {
        ...group,
        attachments: []
      }
    }
    _attachmentGroups[group.id].attachments.push(rest)
  })
  const attachmentGroups = []
  Object.keys(_attachmentGroups).forEach((attachmentGroupKey) => {
    _attachmentGroups[attachmentGroupKey].attachments = _attachmentGroups[
      attachmentGroupKey
    ].attachments.sort((a, b) => (b.createdAt < a.createdAt ? 1 : -1))
  })
  const sortedKeys = Object.keys(_attachmentGroups).sort((a, b) =>
    _attachmentGroups[b].attachments[0].createdAt <
    _attachmentGroups[a].attachments[0].createdAt
      ? 1
      : -1
  )

  sortedKeys.forEach((key, index) => {
    attachmentGroups[index] = _attachmentGroups[key]
  })
  const hasMeasurements = !!_data.study.measurements
  const studyMeasurements = hasMeasurements ? _data.study.measurements : {}

  const hasUserMarks = !!_data.study.userMarks
  const studyUserMarks = hasUserMarks ? _data.study.userMarks : {}

  const isActiveServicePlan = !!currentUser.clinic.servicePlans.find(
    (servicePlan) => servicePlan.active
  )

  const referral = data.study.referrals[0]

  return (
    <Layout gotoLoginPage={props.gotoLoginPage}>
      {isShareMode && (
        <CreateStudyShare
          currentUser={currentUser}
          study={data.study}
          onComplete={() => setShareMode(false)}
          onExit={() => setShareMode(false)}
        />
      )}
      {isCopyMode && (
        <CreateStudyCopy study={data.study} onExit={() => setCopyMode(false)} />
      )}
      {isShowSharesMode && (
        <StudyShares
          studyId={data.study.id}
          onExit={() => setShowSharesMode(false)}
        />
      )}

      <header className='navbar sub-navbar is-fixed-top is-black'>
        <div className='navbar-brand'>
          <a
            role='button'
            className={clsx('navbar-burger', {
              'is-active': isMenuOpen
            })}
            aria-label='menu'
            aria-expanded='false'
            data-target='studyActions'
            onClick={() => toggleMenu(!isMenuOpen)}
          >
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
          </a>
        </div>
        <div
          id='studyActions'
          className={clsx('navbar-menu', {
            'is-active': isMenuOpen
          })}
        >
          <div className='navbar-start'>
            {isAuthorizedAction(
              data.study.authorizedActions,
              'Mutation:studyReportCreate'
            ) &&
              !isAuthorizedAction(
                data.study.authorizedActions,
                'Study:referralCreate'
              ) &&
              data.study.protocol !== 'Holter' &&
              !hasCardiobirdReport &&
              !data.study.hasReport && (
                <a
                  className='navbar-item is-size-6'
                  onClick={() => {
                    if (!isViewerReadyToPrint) return false
                    isMenuOpen && toggleMenu(false)
                    navigate(
                      `/study/${data.study.id}/report/new${
                        window.location.hash
                          ? `?prev_state=${encodeURIComponent(
                              window.location.hash
                            )}`
                          : ''
                      }${window.location.hash}`
                    )
                  }}
                >
                  {locale.create_report}
                </a>
              )}
            {isAuthorizedAction(
              data.study.authorizedActions,
              'Study:reports'
            ) &&
              !isAuthorizedAction(
                data.study.authorizedActions,
                'Study:referralCreate'
              ) &&
              data.study.protocol !== 'Holter' &&
              !hasCardiobirdReport &&
              data.study.hasReport && (
                <a
                  className='navbar-item is-size-6'
                  onClick={() => {
                    isMenuOpen && toggleMenu(false)
                    navigate(
                      `/study/${data.study.id}/report/${
                        data.study.reports[0].id
                      }${
                        window.location.hash
                          ? `?prev_state=${encodeURIComponent(
                              window.location.hash
                            )}`
                          : ''
                      }`
                    )
                  }}
                >
                  {locale.view_report}
                </a>
              )}
            {isAuthorizedAction(
              data.study.authorizedActions,
              'Study:analyzeEcg'
            ) &&
              data.study.protocol === 'Holter' && (
                <>
                  <a
                    className={clsx('navbar-item is-size-6', {
                      'disabled-item': !isAuthorizedAction(
                        data.study.authorizedActions,
                        'Study:analyzeEcgEnable'
                      )
                    })}
                    onClick={async () => {
                      if (
                        !isAuthorizedAction(
                          data.study.authorizedActions,
                          'Study:analyzeEcgEnable'
                        )
                      )
                        return

                      isMenuOpen && toggleMenu(false)

                      const {
                        data: {
                          user: {
                            apiKey: { id: accessKey, accessSecret }
                          }
                        }
                      } = await client.query({
                        query: GET_API_KEY,
                        variables: {
                          id: currentUser.id,
                          isGenerateNewIfNotExist: true
                        }
                      })

                      let status =
                        UPLOAD_STATUS.ecg_signal |
                        UPLOAD_STATUS.uploaded |
                        (data.study.hasEvents ? UPLOAD_STATUS.calculation : 0) |
                        (data.study.hasReport ? UPLOAD_STATUS.report : 0) |
                        (data.study.records.find(
                          (record) =>
                            record.__typename === 'RecordStethoscopeData'
                        )
                          ? UPLOAD_STATUS.audio
                          : 0)

                      const holterRecord = data.study.records.find(
                        (record) => record.__typename === 'RecordEcgHolterData'
                      )

                      const url =
                        'arnika://?study_link=' +
                        encodeURIComponent(
                          props.location.origin +
                            props.location.pathname +
                            `?hash_id=${data.study.id}`
                        ) +
                        '&hash_id=' +
                        data.study.id +
                        '&status=' +
                        status.toString() +
                        '&bucket=' +
                        holterRecord.file.bucket +
                        '&prefix=' +
                        holterRecord.file.key.replace(/\.[^/.]+$/, '') +
                        '&access_key=' +
                        accessKey +
                        '&access_secret=' +
                        accessSecret +
                        '&user_hash=' +
                        currentUser.id

                      setArnikaIFrameUrl(url)
                    }}
                  >
                    {locale.analyze_ecg}
                  </a>
                  <iframe
                    src={arnikaIFrameUrl}
                    style={{
                      position: 'absolute',
                      top: '-1000px',
                      left: '-1000px',
                      visibility: 'hidden'
                    }}
                  />
                </>
              )}
            {isAuthorizedAction(
              data.study.authorizedActions,
              'Mutation:studyShare'
            ) && (
              <a
                className='navbar-item is-size-6'
                onClick={() => {
                  isMenuOpen && toggleMenu(false)
                  setShareMode(true)
                }}
              >
                {locale.ask_second_opinion}
              </a>
            )}
            {isAuthorizedAction(
              data.study.authorizedActions,
              'Study:referralCreate'
            ) && (
              <div className='navbar-item is-size-6 has-dropdown is-hoverable'>
                <a className='navbar-link'>
                  {locale.report_requests.reports_menu}
                </a>
                <div className='navbar-dropdown is-left'>
                  {!(referral || data.study.hasReport) &&
                    data.study.protocol !== 'Holter' && (
                      <Link
                        className='dropdown-item'
                        style={{ textWrap: 'nowrap' }}
                        to={`/study/${data.study.id}/report/new${
                          window.location.hash
                            ? `?prev_state=${encodeURIComponent(
                                window.location.hash
                              )}`
                            : ''
                        }${window.location.hash}`}
                      >
                        {locale.report_requests.create_report}
                      </Link>
                    )}
                  {(referral || data.study.hasReport) &&
                    (data.study.hasReport &&
                    (!referral || referral.status === 'Done') ? (
                      <a
                        className='dropdown-item'
                        style={{ textWrap: 'nowrap' }}
                        onClick={() => {
                          if (isMenuOpen) toggleMenu(false)
                          if (
                            data.study.protocol === 'Holter' ||
                            hasCardiobirdReport
                          ) {
                            setTab('report')
                          } else {
                            navigate(
                              `/study/${data.study.id}/report/${
                                data.study.reports[0].id
                              }${
                                window.location.hash
                                  ? `?prev_state=${encodeURIComponent(
                                      window.location.hash
                                    )}`
                                  : ''
                              }`
                            )
                          }
                        }}
                      >
                        {locale.report_requests.view_report}
                      </a>
                    ) : (
                      <div
                        className='dropdown-item has-text-grey-light'
                        style={{ textWrap: 'nowrap' }}
                      >
                        {interpolate(
                          locale.report_requests.view_report_referral,
                          {
                            name: referral.targetQueue
                              ? referral.targetQueue.name
                              : referral.targetCaregiver.name,
                            date: dayjs(referral.createdAt).format(
                              DATE_FORMAT_CONVERTER[
                                currentUser.settings.dateFormat
                              ]
                            )
                          }
                        )}
                      </div>
                    ))}
                  {!referral && data.study.referralsConfig.length > 0 && (
                    <a
                      className='dropdown-item'
                      style={{ textWrap: 'nowrap' }}
                      onClick={() => {
                        if (isMenuOpen) toggleMenu(false)
                        setIsInDiagnosticReferralMode(true)
                      }}
                    >
                      {locale.report_requests.order_report}
                    </a>
                  )}
                  {referral &&
                    referral.status === 'PaymentPending' &&
                    data.study.referralsConfig.length > 0 && (
                      <a
                        className='dropdown-item'
                        style={{ textWrap: 'nowrap' }}
                        onClick={() => {
                          if (isMenuOpen) toggleMenu(false)
                          setIsInDiagnosticReferralMode(true)
                        }}
                      >
                        {interpolate(
                          locale.report_requests.complete_payment_referral,
                          {
                            name: referral.targetQueue
                              ? referral.targetQueue.name
                              : referral.targetCaregiver.name,
                            date: dayjs(referral.createdAt).format(
                              DATE_FORMAT_CONVERTER[
                                currentUser.settings.dateFormat
                              ]
                            )
                          }
                        )}
                      </a>
                    )}
                  {referral &&
                    isAuthorizedAction(
                      referral.authorizedActionsWrapper.authorizedActions,
                      'Mutation:studyReferralCancel'
                    ) && (
                      <a
                        className='dropdown-item'
                        style={{ textWrap: 'nowrap' }}
                        onClick={() => {
                          if (isMenuOpen) toggleMenu(false)
                          setReferralToCancel(referral)
                        }}
                      >
                        {locale.report_requests.cancel_report_referral}
                      </a>
                    )}
                  {referral &&
                    isAuthorizedAction(
                      referral.authorizedActionsWrapper.authorizedActions,
                      'Referral:invoicePage'
                    ) && (
                      <a
                        className={clsx('dropdown-item', {
                          'button is-text is-loading': isFetchingInvoide
                        })}
                        style={{ textWrap: 'nowrap' }}
                        onClick={async (e) => {
                          e.stopPropagation()
                          setIsFetchingInvoide(true)
                          const { data } = await client.query({
                            query: GET_INVOICE_PAGE,
                            variables: {
                              id: referral.id
                            }
                          })
                          setIsFetchingInvoide(false)
                          if (
                            data &&
                            data.referral &&
                            data.referral.invoicePage
                          ) {
                            window.open(data.referral.invoicePage, '_blank')
                          } else if (
                            data &&
                            data.referral &&
                            !data.referral.invoicePage
                          ) {
                            setIsInvoicePageNotFound(true)
                          }
                        }}
                      >
                        {locale.report_requests.download_invoice}
                      </a>
                    )}
                </div>
              </div>
            )}
            {isAuthorizedAction(
              data.study.authorizedActions,
              'Study:print'
            ) && (
              <a
                className='navbar-item is-size-6'
                target='_blank'
                rel='noreferrer'
                href={`/study/${data.study.id}/report/new?study_mode=true&print=true${window.location.hash}`}
                onClick={() => isMenuOpen && toggleMenu(false)}
              >
                {locale.print}
              </a>
            )}
            {isAuthorizedAction(
              data.study.authorizedActions,
              'Study:generatePdf'
            ) && (
              <a
                className='navbar-item is-size-6'
                onClick={async () => {
                  const credentials = await currentCredentials()

                  const httpRequest = new AWS.HttpRequest(
                    `${process.env.PDFReportsBucketLambdaAccessPoint}/study.${
                      data.study.id
                    }.${base64UrlEncode(window.location.hash.substring(1))}.${
                      currentUser.id
                    }.pdf`,
                    process.env.Region
                  )

                  httpRequest.method = 'GET'
                  httpRequest.headers = {
                    host: process.env.PDFReportsBucketLambdaAccessPoint,
                    'presigned-expires': 900
                  }

                  const signer = new AWS.Signers.V4(
                    httpRequest,
                    's3-object-lambda',
                    true
                  )

                  signer.addAuthorization(credentials, AWS.util.date.getDate())
                  const signature =
                    httpRequest.headers.Authorization.split(' ')[3].split(
                      '='
                    )[1]

                  const url = `https://${httpRequest.headers.host}${httpRequest.path}&X-Amz-Signature=${signature}`

                  isMenuOpen && toggleMenu(false)

                  window.open(url, '_blank')
                }}
              >
                {locale.download_pdf}
              </a>
            )}
            {isAuthorizedAction(
              data.study.authorizedActions,
              'Study:downloadEcg'
            ) &&
              (data.study.protocol === 'Rest' ||
                data.study.protocol === 'Holter') && (
                <a
                  className='navbar-item is-size-6'
                  onClick={() => {
                    const record = data.study.records.find(
                      (record) =>
                        record.__typename === 'RecordEcgRestData' ||
                        record.__typename === 'RecordEcgHolterData'
                    )
                    window.open(record.file.url)
                  }}
                >
                  {locale.download_ecg}
                </a>
              )}
            {isAuthorizedAction(
              data.study.authorizedActions,
              'Mutation:studyCopy'
            ) && (
              <a
                className='navbar-item is-size-6'
                onClick={() => {
                  isMenuOpen && toggleMenu(false)
                  setCopyMode(true)
                }}
              >
                {locale.copy_study}
              </a>
            )}
            {isAuthorizedAction(
              data.study.authorizedActions,
              'Study:showShares'
            ) && (
              <a
                className='navbar-item is-size-6'
                onClick={() => {
                  isMenuOpen && toggleMenu(false)
                  setShowSharesMode(true)
                }}
              >
                {locale.shares.show}
              </a>
            )}
          </div>
        </div>
      </header>
      {isInDiagnosticReferralMode && (
        <DiagnosisReferralForm
          user={{
            id: currentUser.id,
            name: currentUser.settings.displayName,
            email: currentUser.email,
            dateFormat: DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
          }}
          clinic={currentUser.clinic}
          patient={{
            id: data.study.patient.id,
            name: data.study.patient.name,
            externalId: data.study.patient.externalId,
            owner:
              data.study.caregiver.userType === 'Veterinarian' &&
              data.study.patient.animal &&
              data.study.patient.animal.owner
          }}
          studyId={data.study.id}
          study={data.study}
          diagnosticReferralCaregivers={data.study.referralsConfig}
          selectedCaregiverId={
            referral
              ? referral.targetQueue
                ? referral.targetQueue.id
                : referral.targetCaregiver.id
              : queryParams.targetCaregiverId
              ? queryParams.targetCaregiverId
              : data.study.referralsConfig.every(
                  (current) =>
                    data.study.referralsConfig[0].caregiver.id ===
                    current.caregiver.id
                )
              ? data.study.referralsConfig[0].caregiver.id
              : null
          }
          selectedReferralId={referral ? referral.id : queryParams.referralId}
          selectedReferralType={
            referral
              ? referral.referralType
              : queryParams.isLegacyRedirect === '1'
              ? REFERRAL_TYPES_CONVERTER_FROM_LEGACY[queryParams.referralType]
              : queryParams.referralType
              ? queryParams.referralType
              : data.study.referralsConfig.every(
                  (current) =>
                    data.study.referralsConfig[0].caregiver.id ===
                    current.caregiver.id
                )
              ? data.study.referralsConfig[0].referralType
              : null
          }
          isPaymentRequest={
            data.study.referrals.length > 0 ||
            queryParams.paymentRequest === '1'
          }
          onClose={(result) => {
            setIsInDiagnosticReferralMode(false)
            if (result && result.isSuccess) {
              setNotification({
                isSuccess: true,
                message: locale.payments.reports.success
              })
              navigate(window.location.pathname)
            } else if (result && result.isError) {
              setNotification({
                isError: true,
                message: locale.payments.reports.error
              })
            }
          }}
        />
      )}
      {referralToCancel && (
        <ReferralCancel
          referral={referralToCancel}
          onComplete={() => {
            setReferralToCancel(null)
          }}
          onExit={() => setReferralToCancel(null)}
          onUpdate={(
            cache,
            {
              data: {
                studyReferralCancel: { id }
              }
            }
          ) => {
            cache.modify({
              id: cache.identify(data.study),
              fields: {
                referrals: (referrals) => referrals.filter((r) => r.id !== id)
              }
            })
            cache.modify({
              id: cache.identify(currentUser.clinic),
              fields: {
                sourceReferrals: ({ edges, pageInfo }) => ({
                  edges: edges.filter(({ node }) => node.id !== id),
                  pageInfo
                })
              }
            })
          }}
        ></ReferralCancel>
      )}
      {isInvoicePageNotFound && (
        <Modal onClose={() => setIsInvoicePageNotFound(false)}>
          <div className='field'>
            <label className='label'>
              {locale.report_requests.invoice_not_found}
            </label>
            <div>
              <span>
                {locale.report_requests.invoice_not_found_contact_support}
              </span>{' '}
              <a
                href='mailto:billing@beecardia.com'
                target='_blank'
                rel='noreferrer'
              >
                billing@beecardia.com
              </a>
            </div>
            <div className='has-text-right'>
              <button
                className='button is-primary mt-4'
                onClick={() => setIsInvoicePageNotFound(false)}
              >
                {locale.ok}
              </button>
            </div>
          </div>
        </Modal>
      )}
      {arnikaIFrameUrl && (
        <Modal>
          <p>{locale.analyze_ecg_in_progress}</p>
        </Modal>
      )}
      <Notification
        notification={notification}
        onClose={() => setNotification(null)}
      />
      <div className='section' id='study-main-section'>
        <div className='container'>
          <div className='box'>
            <div className='level'>
              <div className='level-left'>
                <div className='level-item has-text-weight-bold'>
                  {locale.study_details.name}
                </div>
                <div className='level-item'>
                  <span>
                    {localizePatientName(data.study.patient.name, locale)}
                  </span>
                </div>
                {data.study.caregiver.userType === 'Veterinarian' &&
                  data.study.patient.animal &&
                  data.study.patient.animal.owner && (
                    <>
                      <div className='level-item separator-item'>|</div>
                      <div className='level-item has-text-weight-bold'>
                        {locale.study_details.owner}
                      </div>
                      <div className='level-item'>
                        {data.study.patient.animal.owner}
                      </div>
                    </>
                  )}
                {data.study.caregiver.userType === 'Veterinarian' &&
                  data.study.anonymousPatientData.animal &&
                  data.study.anonymousPatientData.animal.type && (
                    <>
                      <div className='level-item separator-item'>|</div>
                      <div className='level-item has-text-weight-bold'>
                        {locale.study_details.species}
                      </div>
                      <div className='level-item'>
                        {
                          locale.animals[
                            ANIMAL_TYPE_NEW_TO_LEGACY_STRING[
                              data.study.anonymousPatientData.animal.type
                            ]
                          ]
                        }
                      </div>
                    </>
                  )}
                {data.study.anonymousPatientData.gender && (
                  <>
                    <div className='level-item separator-item'>|</div>
                    <div className='level-item has-text-weight-bold'>
                      {locale.study_details.gender}
                    </div>
                    <div className='level-item'>
                      {
                        locale[
                          data.study.anonymousPatientData.gender.toLowerCase()
                        ]
                      }
                    </div>
                  </>
                )}
                {data.study.caregiver.userType === 'Physician' &&
                typeof data.study.anonymousPatientData.height === 'number' &&
                data.study.anonymousPatientData.height > 0 ? (
                  <>
                    <div className='level-item separator-item'>|</div>
                    <div className='level-item has-text-weight-bold'>
                      {locale.study_details.height}
                    </div>
                    <div className='level-item'>
                      {interpolate(locale.unit_tmpl, {
                        value: data.study.anonymousPatientData.height,
                        type: locale[
                          HEIGHT_CONVERTER[
                            data.study.anonymousPatientData.unitSystem
                          ]
                        ]
                      })}
                    </div>
                  </>
                ) : null}
                {typeof data.study.anonymousPatientData.weight === 'number' &&
                data.study.anonymousPatientData.weight > 0 ? (
                  <>
                    <div className='level-item separator-item'>|</div>
                    <div className='level-item has-text-weight-bold'>
                      {locale.study_details.weight}
                    </div>
                    <div className='level-item'>
                      {interpolate(locale.unit_tmpl, {
                        value: data.study.anonymousPatientData.weight,
                        type: locale[
                          WEIGHT_CONVERTER[
                            data.study.anonymousPatientData.unitSystem
                          ]
                        ]
                      })}
                    </div>
                  </>
                ) : null}
                {Number.isInteger(data.study.anonymousPatientData.age) &&
                data.study.anonymousPatientData.age > 0 ? (
                  <>
                    <div className='level-item separator-item'>|</div>
                    <div className='level-item has-text-weight-bold'>
                      {locale.study_details.age}
                    </div>
                    <div className='level-item'>
                      {data.study.caregiver.userType === 'Veterinarian'
                        ? interpolate(locale.unit_tmpl, {
                            value: data.study.anonymousPatientData.age,
                            type: locale.study_header[
                              getAgeUnitsLocalizationKey(
                                data.study.anonymousPatientData.age,
                                data.study.anonymousPatientData.ageUnits
                                  ? data.study.anonymousPatientData.ageUnits.toLowerCase()
                                  : 'years'
                              )
                            ]
                          })
                        : interpolate(locale.age_with_birthdate, {
                            age: interpolate(locale.unit_tmpl, {
                              value: data.study.anonymousPatientData.age,
                              type: locale.study_header[
                                getAgeUnitsLocalizationKey(
                                  data.study.anonymousPatientData.age,
                                  data.study.anonymousPatientData.ageUnits
                                    ? data.study.anonymousPatientData.ageUnits.toLowerCase()
                                    : 'years'
                                )
                              ]
                            }),
                            birthdate: dayjs(
                              data.study.patient.birthdate
                            ).format(
                              `${
                                DATE_FORMAT_CONVERTER[
                                  currentUser.settings.dateFormat
                                ]
                              }`
                            )
                          })}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className='level'>
              <div className='level-left'>
                <div className='level-item has-text-weight-bold'>
                  {locale.study_details_recorded_at}
                </div>
                <div className='level-item'>
                  {adjustTimeToTimezone(
                    data.study.createdAt,
                    data.study.timezoneOffset
                  ).format(
                    `${
                      DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                    } HH:mm:ss`
                  )}
                </div>
                {['Rest', 'Holter', 'Stethoscope'].includes(
                  data.study.protocol
                ) && data.study.duration ? (
                  <>
                    <div className='level-item separator-item'>|</div>
                    <div className='level-item has-text-weight-bold'>
                      {locale.study_details_duration}
                    </div>
                    <div className='level-item'>
                      {durationToHuman(data.study.duration)}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {!isActiveServicePlan ? (
            <div className='box content'>
              <p className='has-text-weight-bold'>
                {locale.service_plan.activate_message}
              </p>
              <p className='has-text-centered'>
                <button
                  className={clsx('button is-outlined is-primary', {
                    'is-loading': loadingServicePlanCheckoutPage
                  })}
                  onClick={async () => {
                    const result = await getServicePlanCheckoutPage()
                    if (result.data) {
                      window.location = result.data.servicePlan.checkoutPage
                    }
                  }}
                  disabled={loadingServicePlanCheckoutPage}
                >
                  {locale.service_plan.activate_cta}
                </button>
              </p>
            </div>
          ) : (
            <>
              <div className='tabs is-boxed'>
                <ul>
                  {data.study.protocol === 'Attachments' && (
                    <li
                      className={clsx({
                        'is-active':
                          tab === 'attachments' ||
                          (!tab && data.study.protocol === 'Attachments')
                      })}
                    >
                      <a onClick={() => setTab('attachments')}>
                        {interpolate(locale.attachments.attachments_count, {
                          count: attachmentGroups.length
                        })}
                      </a>
                    </li>
                  )}
                  {data.study.records.map((record, index) => (
                    <li
                      key={record.id}
                      className={clsx({
                        'is-active':
                          tab === record.__typename ||
                          (!tab &&
                            data.study.protocol !== 'Attachments' &&
                            index === 0)
                      })}
                    >
                      <a
                        className={clsx({
                          button: index > 0 && !isViewerReadyToPrint,
                          'is-static': index > 0 && !isViewerReadyToPrint
                        })}
                        onClick={() => setTab(record.__typename)}
                      >
                        {RECORD_TYPE_TO_TAB_TITLE[record.__typename]}
                      </a>
                    </li>
                  ))}
                  {(data.study.protocol === 'Holter' ||
                    hasCardiobirdReport) && (
                    <li className={clsx({ 'is-active': tab === 'report' })}>
                      <a
                        className={clsx({
                          button: !signedReportUrl || !isViewerReadyToPrint,
                          'is-static': !signedReportUrl || !isViewerReadyToPrint
                        })}
                        onClick={() => setTab('report')}
                      >
                        {locale.studies.report}
                      </a>
                    </li>
                  )}
                  {data.study.protocol === 'Rest' && (
                    <li className={clsx({ 'is-active': tab === 'events' })}>
                      <a
                        className={clsx({
                          button: !isEventsTabActive || !isViewerReadyToPrint,
                          'is-static':
                            !isEventsTabActive || !isViewerReadyToPrint
                        })}
                        onClick={() => setTab('events')}
                      >
                        {locale.events_and_calculations}
                      </a>
                    </li>
                  )}
                  {attachmentGroups.length > 0 &&
                    data.study.protocol !== 'Attachments' && (
                      <li
                        className={clsx({
                          'is-active': tab === 'attachments'
                        })}
                      >
                        <a
                          className={clsx({
                            button: !isViewerReadyToPrint,
                            'is-static': !isViewerReadyToPrint
                          })}
                          onClick={() => setTab('attachments')}
                        >
                          {interpolate(locale.attachments.attachments_count, {
                            count: attachmentGroups.length
                          })}
                        </a>
                      </li>
                    )}
                  <li className={clsx({ 'is-active': tab === 'patient' })}>
                    <a
                      className={clsx({
                        button: !isViewerReadyToPrint,
                        'is-static': !isViewerReadyToPrint
                      })}
                      onClick={() => setTab('patient')}
                    >
                      {locale.patient_details}
                    </a>
                  </li>
                  {isAuthorizedAction(
                    data.study.authorizedActions,
                    'Study:comments'
                  ) && (
                    <li className={clsx({ 'is-active': tab === 'discussion' })}>
                      <a
                        className={clsx({
                          button: !isViewerReadyToPrint,
                          'is-static': !isViewerReadyToPrint
                        })}
                        onClick={() => setTab('discussion')}
                      >
                        {locale.discussion}
                      </a>
                    </li>
                  )}
                  {isAuthorizedAction(
                    data.study.authorizedActions,
                    'Study:diagnosis'
                  ) &&
                    data.study.protocol !== 'Holter' && (
                      <li
                        className={clsx({ 'is-active': tab === 'diagnosis' })}
                      >
                        <a
                          className={clsx({
                            button: !isViewerReadyToPrint,
                            'is-static': !isViewerReadyToPrint
                          })}
                          onClick={() => setTab('diagnosis')}
                        >
                          {locale.diagnosis}
                        </a>
                      </li>
                    )}
                  {/* <li className={clsx({ 'is-active': tab === 'reports' })}>
                  <a onClick={() => setTab('reports')}>Reports</a>
                </li> */}
                  {/* <li
                  className={clsx({
                    'is-active': tab === 'referrals',
                    'is-hidden': data.study.referrals.length === 0
                  })}
                >
                  <a onClick={() => setTab('referrals')}>Referrals</a>
                </li> */}
                  {isAuthorizedAction(
                    data.study.authorizedActions,
                    'Study:auditStream'
                  ) && (
                    <li className={clsx({ 'is-active': tab === 'audit' })}>
                      <a
                        className={clsx({
                          button: !isViewerReadyToPrint,
                          'is-static': !isViewerReadyToPrint
                        })}
                        onClick={() => setTab('audit')}
                      >
                        {locale.audit}
                      </a>
                    </li>
                  )}
                  {isAuthorizedAction(
                    data.study.authorizedActions,
                    'Study:logStream'
                  ) && (
                    <li className={clsx({ 'is-active': tab === 'log' })}>
                      <a
                        className={clsx({
                          button: !isViewerReadyToPrint,
                          'is-static': !isViewerReadyToPrint
                        })}
                        onClick={() => setTab('log')}
                      >
                        log
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <div className='box'>
                {data.study.protocol === 'Attachments' && (
                  <div
                    className={clsx({
                      'is-hidden': !(
                        tab === 'attachments' ||
                        (!tab && data.study.protocol === 'Attachments')
                      )
                    })}
                  >
                    {attachmentGroups.length === 0 ? (
                      <div>
                        <p>{locale.attachments.waiting}</p>
                      </div>
                    ) : (
                      <StudyAttachments
                        attachmentGroups={attachmentGroups}
                        dateFormat={`${
                          DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                        } HH:mm:ss`}
                      />
                    )}
                  </div>
                )}
                {data.study.records.map((record, index) => {
                  if (record.__typename === 'RecordEcgRestData') {
                    return (
                      <div
                        key={record.id}
                        className={clsx({
                          'is-hidden':
                            tab !== record.__typename && (tab || index > 0)
                        })}
                      >
                        <BCViewer
                          recordHash={data.study.id}
                          recordUrl={record.file.url}
                          recordType={2}
                          studyType={1}
                          hasEvents={data.study.hasEvents}
                          config={{ BC_VIEWER_HEIGHT: 708 }}
                          zoom={
                            PAPER_SPEED_CONVERTER[
                              currentUser.settings.viewerDefaultPaperSpeed
                            ]
                          }
                          amplitude={
                            AMPLITUTE_CONVERTER[
                              currentUser.settings.viewerDefaultAmplitude
                            ]
                          }
                          defaultQrsIntervals={
                            QRS_INTERVALS_CONVERTER[
                              currentUser.settings.viewerDefaultQRSIntervals
                            ]
                          }
                          gridType={
                            GRID_TYPE_CONVERTER[
                              currentUser.settings.viewerGridType
                            ]
                          }
                          displayPredictiveMeasurement={
                            currentUser.settings
                              .viewerDisplayPredictiveMeasurement
                          }
                          dateFormat={currentUser.settings.dateFormat}
                          measurements={
                            hasMeasurements
                              ? {
                                  measurement_values:
                                    studyMeasurements.measurements
                                }
                              : {}
                          }
                          userMarks={
                            hasUserMarks
                              ? JSON.parse(studyUserMarks.userMarks)
                              : []
                          }
                          studyAuthorizedActions={data.study.authorizedActions}
                          isCaregiverVeterinarian={
                            data.study.caregiver.userType === 'Veterinarian'
                          }
                          callbacks={callbacks}
                        />
                      </div>
                    )
                  }
                  if (record.__typename === 'RecordEcgHolterData') {
                    return (
                      <div
                        key={record.id}
                        className={clsx({
                          'is-hidden':
                            tab !== record.__typename && (tab || index > 0)
                        })}
                      >
                        <BCViewer
                          recordHash={data.study.id}
                          recordUrl={record.file.url}
                          recordType={2}
                          studyType={2}
                          hasEvents={data.study.hasEvents}
                          config={{ BC_VIEWER_HEIGHT: 708 }}
                          zoom={
                            PAPER_SPEED_CONVERTER[
                              currentUser.settings.viewerDefaultPaperSpeed
                            ]
                          }
                          amplitude={
                            AMPLITUTE_CONVERTER[
                              currentUser.settings.viewerDefaultAmplitude
                            ]
                          }
                          defaultQrsIntervals={
                            QRS_INTERVALS_CONVERTER[
                              currentUser.settings.viewerDefaultQRSIntervals
                            ]
                          }
                          gridType={
                            GRID_TYPE_CONVERTER[
                              currentUser.settings.viewerGridType
                            ]
                          }
                          displayPredictiveMeasurement={
                            currentUser.settings
                              .viewerDisplayPredictiveMeasurement
                          }
                          dateFormat={currentUser.settings.dateFormat}
                          studyAuthorizedActions={data.study.authorizedActions}
                          isCaregiverVeterinarian={
                            data.study.caregiver.userType === 'Veterinarian'
                          }
                        />
                      </div>
                    )
                  }
                  if (
                    record.__typename === 'RecordStethoscopeData' &&
                    (tab === record.__typename || (!tab && index === 0))
                  ) {
                    return (
                      <div key={record.id}>
                        <BCStethoscopeViewer recordUrl={record.file.url} />
                      </div>
                    )
                  }
                  if (record.__typename === 'RecordSpirometerData') {
                    return (
                      <div
                        key={record.id}
                        className={clsx({
                          'is-hidden':
                            tab !== record.__typename && (tab || index > 0)
                        })}
                      >
                        <BCSpiroViewer recordUrl={record.file.url} />
                      </div>
                    )
                  }
                  if (record.__typename === 'RecordQuestionnaireData') {
                    return (
                      <div
                        key={record.id}
                        className={clsx({
                          'is-hidden': !(
                            tab === record.__typename ||
                            (!tab &&
                              data.study.protocol !== 'Attachments' &&
                              index === 0)
                          )
                        })}
                      >
                        <div className='level'>
                          <div className='level-left'></div>
                          <div className='level-right'>
                            <div className='level-item'>
                              <a
                                className='button is-white'
                                target='_blank'
                                rel='noreferrer'
                                href={`/study/${data.study.id}/report/new?questionnaire=true&print=true${window.location.hash}`}
                              >
                                <span className='icon'>
                                  <FontAwesomeIcon icon={faPrint} />
                                </span>
                                <span>{locale.print}</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <Questionnaire items={record.items} />
                      </div>
                    )
                  }
                })}
                {attachmentGroups.length > 0 &&
                  data.study.protocol !== 'Attachments' && (
                    <div
                      className={clsx({
                        'is-hidden': tab !== 'attachments'
                      })}
                    >
                      <StudyAttachments
                        attachmentGroups={attachmentGroups}
                        dateFormat={`${
                          DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                        } HH:mm:ss`}
                      />
                    </div>
                  )}
                <div className={clsx({ 'is-hidden': tab !== 'report' })}>
                  <iframe
                    src={signedReportUrl}
                    type='application/pdf'
                    border='0'
                    style={{ width: '100%', height: '708px' }}
                  />
                </div>
                <div className={clsx({ 'is-hidden': tab !== 'patient' })}>
                  <PatientDetails
                    patient={data.study.patient}
                    anonymousPatientData={data.study.anonymousPatientData}
                    studies={data.study.patient.studies}
                    currentStudyId={data.study.id}
                    dateFormat={`${
                      DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                    } HH:mm:ss`}
                    caregiverUserType={data.study.caregiver.userType}
                  />
                </div>
                {isAuthorizedAction(
                  data.study.authorizedActions,
                  'Study:comments'
                ) && (
                  <div className={clsx({ 'is-hidden': tab !== 'discussion' })}>
                    <StudyComments
                      comments={data.study.comments}
                      study={data.study}
                      studyId={props.id}
                      currentUser={currentUser}
                      dateFormat={`${
                        DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                      } HH:mm:ss`}
                    />
                  </div>
                )}
                {data.study.protocol === 'Rest' && (
                  <div
                    id='study-summary-tab'
                    className={clsx({ 'is-hidden': tab !== 'events' })}
                  >
                    <StudySummary
                      animalType={
                        data.study.caregiver.userType === 'Veterinarian' &&
                        data.study.patient.animal &&
                        data.study.patient.animal.type
                      }
                      activateTab={() => setEventsTabToActive(true)}
                      setInitialMEA={setInitialMEA}
                      studyId={data.study.id}
                      isCaregiverVeterinarian={
                        data.study.caregiver.userType === 'Veterinarian'
                      }
                      currentUser={currentUser}
                    />
                  </div>
                )}
                {/* <div className={clsx({ 'is-hidden': tab !== 'reports' })}>
              <StudyReports
                studyId={data.study.id}
                reports={data.study.reports}
                dateFormat={`${
                  DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                } HH:mm:ss`}
              />
            </div> */}
                {/* <div className={clsx({ 'is-hidden': tab !== 'referrals' })}>
              <StudyReferrals
                referrals={data.study.referrals}
                dateFormat={`${
                  DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                } HH:mm:ss`}
              />
            </div> */}
                {isAuthorizedAction(
                  data.study.authorizedActions,
                  'Study:diagnosis'
                ) && (
                  <div className={clsx({ 'is-hidden': tab !== 'diagnosis' })}>
                    <DiagnosisTab
                      isRest={data.study.protocol === 'Rest'}
                      diagnosis={data.study.diagnosis}
                      initialMEA={initialMEA}
                      initialMeasurements={
                        hasMeasurements
                          ? studyMeasurements
                          : currentMeasurements
                      }
                      dateFormat={`${
                        DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                      } HH:mm:ss`}
                      animalType={
                        data.study.caregiver.userType === 'Veterinarian' &&
                        data.study.anonymousPatientData.animal &&
                        data.study.anonymousPatientData.animal.type
                      }
                      studyId={data.study.id}
                      forceReRender={forceReRender}
                    ></DiagnosisTab>
                  </div>
                )}
                {isAuthorizedAction(
                  data.study.authorizedActions,
                  'Study:auditStream'
                ) && (
                  <div className={clsx({ 'is-hidden': tab !== 'audit' })}>
                    <StudyAudits
                      loading={loadingStudyAudits}
                      data={studyAuditsData}
                      studyAuditsFetchMore={studyAuditsFetchMore}
                      studyId={props.id}
                      currentUser={currentUser}
                    ></StudyAudits>
                  </div>
                )}
                {isAuthorizedAction(
                  data.study.authorizedActions,
                  'Study:logStream'
                ) && (
                  <div className={clsx({ 'is-hidden': tab !== 'log' })}>
                    <StudyLog
                      loading={loadingStudyLog}
                      data={studyLogData}
                      studyLogFetchMore={studyLogFetchMore}
                      studyId={props.id}
                      currentUser={currentUser}
                    ></StudyLog>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Study
