import React, { useState } from 'react'
import { GET_SERVICE_PLANS } from '../queries'
import { useQuery } from '@apollo/client'
import useMemoryPagination, {
  prev,
  next
} from '../../hooks/useMemoryPagination'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import UpdateServicePlanPrice from './UpdateServicePlanPrice'
import UpdateServicePlanBillingDate from './UpdateServicePlanBillingDate'
import UpdateServicePlanAutoRenew from './UpdateServicePlanAutoRenew'
import AttachToServicePlan from './AttachToServicePlan'

const FIRST = 20

const AdminAttachedServicePlans = () => {
  const [editPriceServicePlan, setEditPriceServicePlan] = useState(null)
  const [editBillingDateServicePlan, setEditBillingDateServicePlan] =
    useState(null)
  const [editAutoRenewServicePlan, setEditAutoRenewServicePlan] = useState(null)
  const [isAttachToServicePlanMode, setAttachToServicePlanMode] =
    useState(false)
  const [paginationState, dispatch] = useMemoryPagination()

  const { loading, error, data } = useQuery(GET_SERVICE_PLANS, {
    variables: { first: FIRST, after: paginationState }
  })

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <div> error... </div>}

      {editPriceServicePlan && (
        <UpdateServicePlanPrice
          servicePlan={editPriceServicePlan}
          onComplete={() => {
            setEditPriceServicePlan(null)
          }}
          onExit={() => setEditPriceServicePlan(null)}
        ></UpdateServicePlanPrice>
      )}
      {editBillingDateServicePlan && (
        <UpdateServicePlanBillingDate
          servicePlan={editBillingDateServicePlan}
          onComplete={() => {
            setEditBillingDateServicePlan(null)
          }}
          onExit={() => setEditBillingDateServicePlan(null)}
        ></UpdateServicePlanBillingDate>
      )}
      {editAutoRenewServicePlan && (
        <UpdateServicePlanAutoRenew
          servicePlan={editAutoRenewServicePlan}
          onComplete={() => {
            setEditAutoRenewServicePlan(null)
          }}
          onExit={() => setEditAutoRenewServicePlan(null)}
        ></UpdateServicePlanAutoRenew>
      )}
      {isAttachToServicePlanMode && (
        <AttachToServicePlan
          servicePlan={isAttachToServicePlanMode}
          onComplete={() => {
            setAttachToServicePlanMode(false)
          }}
          onExit={() => setAttachToServicePlanMode(false)}
        ></AttachToServicePlan>
      )}
      {data && (
        <div>
          <div className='table-container'>
            <table className='table is-fullwidth is-hoverable'>
              <thead>
                <tr>
                  <th>Created At</th>
                  <th>Attached Users</th>
                  <th>Active</th>
                  <th>Auto renew</th>
                  <th>Product</th>
                  <th>Contract</th>
                  <th>Subscription id</th>
                  <th>Price</th>
                  <th>Currency</th>
                  <th>Credit Card</th>
                  <th>Next billing date</th>
                  <th>paid with email</th>
                  <th>Change Price</th>
                  <th>Change billing date</th>
                  <th>Cancel auto renew</th>
                  <th>Attach user</th>
                </tr>
              </thead>
              <tbody>
                {data.admin.servicePlans.edges.map(({ node: servicePlan }) => (
                  <tr key={servicePlan.id} style={{ textWrap: 'nowrap' }}>
                    <td className='is-vcentered'>
                      {dayjs(servicePlan.createdAt).format('YYYY-MM-DD')}
                    </td>
                    <td className='is-vcentered'>
                      {servicePlan.clinics
                        .map(
                          (clinic) =>
                            `${clinic.owner.settings.displayName} (${clinic.owner.email})`
                        )
                        .join(', ')}
                    </td>
                    <td className='is-vcentered'>
                      <FontAwesomeIcon
                        icon={servicePlan.active ? faCheck : faTimes}
                      />
                    </td>
                    <td className='is-vcentered'>
                      <FontAwesomeIcon
                        icon={servicePlan.autoRenew ? faCheck : faTimes}
                      />
                    </td>
                    <td className='is-vcentered'>{servicePlan.product.name}</td>
                    <td className='is-vcentered'>
                      {servicePlan.contract.name}
                    </td>
                    <td className='is-vcentered'>{servicePlan.id}</td>
                    <td className='is-vcentered'>
                      {servicePlan.contract.price}
                    </td>
                    <td className='is-vcentered'>
                      {servicePlan.contract.currency}
                    </td>
                    <td className='is-vcentered'>
                      {`${servicePlan.paymentToken.creditCard.type} ${servicePlan.paymentToken.creditCard.number} ${servicePlan.paymentToken.creditCard.month}/${servicePlan.paymentToken.creditCard.year}`}
                    </td>
                    <td className='is-vcentered'>
                      {servicePlan.contract.nextBillingDate}
                    </td>
                    <td className='is-vcentered'>{servicePlan.payer}</td>
                    <td className='is-vcentered'>
                      <button
                        className='button'
                        onClick={() => setEditPriceServicePlan(servicePlan)}
                      >
                        Change price
                      </button>
                    </td>
                    <td className='is-vcentered'>
                      <button
                        className='button'
                        onClick={() =>
                          setEditBillingDateServicePlan(servicePlan)
                        }
                      >
                        Change billing date
                      </button>
                    </td>
                    <td className='is-vcentered'>
                      <button
                        className='button'
                        onClick={() => setEditAutoRenewServicePlan(servicePlan)}
                        disabled={!servicePlan.autoRenew}
                      >
                        Cancel
                      </button>
                    </td>
                    <td className='is-vcentered'>
                      <button
                        className='button'
                        onClick={() => setAttachToServicePlanMode(servicePlan)}
                      >
                        Attach user
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <nav className='pagination' role='navigation' aria-label='pagination'>
            <a
              className='pagination-previous'
              disabled={!data.admin.servicePlans.pageInfo.hasPreviousPage}
              onClick={() => {
                if (data.admin.servicePlans.pageInfo.hasPreviousPage) {
                  dispatch(prev())
                }
              }}
            >
              Previous
            </a>
            <a
              className='pagination-next'
              disabled={!data.admin.servicePlans.pageInfo.hasNextPage}
              onClick={() => {
                if (data.admin.servicePlans.pageInfo.hasNextPage) {
                  dispatch(next(data.admin.servicePlans.pageInfo.endCursor))
                }
              }}
            >
              Next
            </a>
          </nav>
        </div>
      )}
    </div>
  )
}

export default AdminAttachedServicePlans
