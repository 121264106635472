import React, { useContext } from 'react'
import { GET_CLINIC_AUDITS } from '../queries'
import { useQuery } from '@apollo/client'
import AuthContext from '../AuthContext'
import { DATE_FORMAT_CONVERTER } from '../../../utils/utils'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

const FIRST = 20

const ClinicAudit = ({ clinic }) => {
  const currentUser = useContext(AuthContext)
  const { loading, data, fetchMore } = useQuery(GET_CLINIC_AUDITS, {
    variables: {
      id: clinic.id,
      first: FIRST
    }
  })
  return (
    <div>
      {loading && <p>Loading...</p>}
      {data &&
        data.clinic.auditStream.edges.map(({ node: audit }) => (
          <article className='media' key={audit.id}>
            <div className='media-content'>
              <div className='content'>
                <p>
                  <span className='is-size-6'>
                    {dayjs(audit.createdAt).format(
                      `${
                        DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                      } HH:mm:ss`
                    )}
                  </span>
                  <span className='ml-2 mr-2'>-</span>
                  <span className='has-text-weight-bold'>{audit.event}</span>

                  <a
                    href={audit.url}
                    className='ml-4'
                    target='_blank'
                    rel='noreferrer'
                    title='Open in cloudwatch'
                  >
                    <FontAwesomeIcon icon={faUpRightFromSquare} />
                  </a>
                </p>
                <div className='accordion'>
                  <input type='checkbox' id={`accordion_${audit.id}`} hidden />
                  <label
                    className='accordion-header'
                    htmlFor={`accordion_${audit.id}`}
                  >
                    <a className='button is-text has-text-grey'>details</a>
                  </label>
                  <div className='accordion-body'>
                    <code style={{ whiteSpace: 'pre-wrap', color: 'black' }}>
                      {JSON.stringify(JSON.parse(audit.data), null, '\t')}
                    </code>
                  </div>
                </div>
              </div>
            </div>
          </article>
        ))}

      {data && (
        <button
          className='button is-primary mt-5'
          disabled={!data.clinic.auditStream.pageInfo.hasNextPage}
          onClick={() => {
            if (data.clinic.auditStream.pageInfo.hasNextPage) {
              fetchMore({
                variables: {
                  id: clinic.id,
                  after: data.clinic.auditStream.pageInfo.endCursor
                }
              })
            }
          }}
        >
          Load more
        </button>
      )}
    </div>
  )
}

export default ClinicAudit
