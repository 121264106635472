import React, { useContext, useState, useReducer } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPencilAlt,
  faTrash,
  faStar as faStarSolid
} from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons'
import AuthContext from './AuthContext'
import { GET_USER_DATA } from './queries'
import Layout from './Layout'
import ConclusionTemplateEdit from './ConclusionTemplateEdit'
import {
  generateDefaultName,
  generateDefaultDisplayName,
  removeEmptyObjects,
  objectToFieldsConverter
} from '../../utils/utils'
import DigitalSignatureManager from './DigitalSigntaureManager'
import Loader from './Loader'
import { useLocalization } from './LocalizationProvider'
import SomethingWentWrong from './SomethingWentWrong'

function reducer(state, action) {
  if (action.type === 'INIT') return {}
  const newState = { ...state }
  if (action.value === action.original) {
    delete newState[action.key]
  } else newState[action.key] = action.value
  return newState
}

const USER_SETTINGS_UPDATE = gql`
  mutation USER_SETTINGS_UPDATE($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      id
    }
  }
`

const Settings = () => {
  const [conclusionTemplateId, setConclusionTemplateId] = useState(null)
  const [settings, dispatchSettings] = useReducer(reducer, {})
  const [isSettingsUpdated, setIsSettingsUpdated] = useState(false)
  const [isDigitalSignatureEditMode, setDigitalSignatureEditMode] =
    useState(false)

  const [isDataFetched, setIsDataFetched] = useState(false)
  const [reportConclusionTemplates, dispatchReportConclusionTemplates] =
    useReducer(reducer, {})
  const [defaultConclusionTemplate, setDefaultConclusionTemplate] = useState('')
  const { locale } = useLocalization()

  const { id } = useContext(AuthContext)

  const { loading, error, data } = useQuery(GET_USER_DATA, {
    variables: { id, isAuthenticated: true },
    onCompleted: (data) => {
      if (!isDataFetched) {
        const reportConclusionTemplates = data.user.settings
          .reportConclusionTemplates
          ? JSON.parse(data.user.settings.reportConclusionTemplates)
          : { list: {}, default_template: '' }

        Object.keys(reportConclusionTemplates.list).forEach((key) => {
          dispatchReportConclusionTemplates({
            key: key,
            value: reportConclusionTemplates.list[key]
          })
        })

        setDefaultConclusionTemplate(reportConclusionTemplates.default_template)
        setIsDataFetched(true)
      }
    }
  })

  const [updateSettings, { loading: updateSettingsLoading }] = useMutation(
    USER_SETTINGS_UPDATE,
    {
      onCompleted: () => {
        dispatchSettings({ type: 'INIT' })
        setIsSettingsUpdated(true)
      }
    }
  )

  const hasNewSettingsData = Object.keys(settings).length > 0
  const hasConclusionTemplateUpdated =
    JSON.stringify({
      list: reportConclusionTemplates,
      default_template: defaultConclusionTemplate
    }) !== data.user.settings.reportConclusionTemplates

  if (loading)
    return (
      <Layout>
        <Loader />
      </Layout>
    )

  if (error) return <SomethingWentWrong />

  const defaultDisplayName = generateDefaultDisplayName(
    generateDefaultName(
      data.user.firstName,
      data.user.middleName,
      data.user.lastName
    )
  )

  return (
    <Layout>
      {isDigitalSignatureEditMode && (
        <DigitalSignatureManager
          user={data.user}
          currentDigitalSignature={data.user.settings.reportDigitalSignature}
          onComplete={() => setDigitalSignatureEditMode(false)}
          onExit={() => setDigitalSignatureEditMode(false)}
        />
      )}

      {conclusionTemplateId && (
        <ConclusionTemplateEdit
          templateId={conclusionTemplateId}
          name={
            conclusionTemplateId !== 'new'
              ? reportConclusionTemplates[conclusionTemplateId].name
              : ''
          }
          text={
            conclusionTemplateId !== 'new'
              ? reportConclusionTemplates[conclusionTemplateId].text
              : ''
          }
          onSave={(templateId, name, text) => {
            templateId = templateId === 'new' ? `id${Date.now()}` : templateId
            dispatchReportConclusionTemplates({
              key: templateId,
              value: { name, text }
            })
            setConclusionTemplateId(null)
          }}
          onExit={() => setConclusionTemplateId(null)}
        />
      )}
      <div className='section settings-section'>
        <div className='container'>
          <div className='box'>
            <h2 className='subtitle is-5 has-text-weight-medium'>
              {locale.account_settings}
            </h2>
            <hr />
            <div className='field is-horizontal'>
              <div className='field-label is-flex is-align-items-center'>
                <label className='label'>{locale.language}</label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <div className='select'>
                      <select
                        value={
                          typeof settings.locale === 'undefined'
                            ? data.user.settings.locale || ''
                            : settings.locale
                        }
                        onChange={(e) =>
                          dispatchSettings({
                            key: 'locale',
                            value: e.target.value,
                            original: data.user.settings.locale
                          })
                        }
                      >
                        <option value='en_US'>{locale.en}</option>
                        <option value='ru_RU'>{locale.ru}</option>
                        <option value='uk_UA'>{locale.uk}</option>
                        <option value='zh_TW'>{locale.zh}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-label is-flex is-align-items-center'>
                <label className='label'>{locale.profile.unit_system}</label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <div className='select'>
                      <select
                        value={
                          typeof settings.unitSystem === 'undefined'
                            ? data.user.settings.unitSystem || ''
                            : settings.unitSystem
                        }
                        onChange={(e) =>
                          dispatchSettings({
                            key: 'unitSystem',
                            value: e.target.value,
                            original: data.user.settings.unitSystem
                          })
                        }
                      >
                        <option value='Metric'>{locale.profile.metric}</option>
                        <option value='Imperial'>
                          {locale.profile.imperial}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-label is-flex is-align-items-center'>
                <label className='label'>{locale.profile.date_format}</label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <div className='select'>
                      <select
                        value={
                          typeof settings.dateFormat === 'undefined'
                            ? data.user.settings.dateFormat || ''
                            : settings.dateFormat
                        }
                        onChange={(e) =>
                          dispatchSettings({
                            key: 'dateFormat',
                            value: e.target.value
                          })
                        }
                      >
                        <option value='MMDDYYYY'>
                          {locale.date_formats['mm/dd/yyyy']}
                        </option>
                        <option value='DDMMYYYY'>
                          {locale.date_formats['dd/mm/yyyy']}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal mb-0'>
              <div className='field-label is-flex is-align-items-center has-text-left'>
                <label className='label mb-0'>
                  {locale.profile.display_name}
                </label>
              </div>
              <div className='field-body is-align-items-center'>
                <div className='field is-narrow'>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      value={
                        typeof settings.displayName === 'undefined'
                          ? data.user.settings.displayName || ''
                          : settings.displayName
                      }
                      placeholder={defaultDisplayName}
                      onChange={(e) =>
                        dispatchSettings({
                          key: 'displayName',
                          value: e.target.value,
                          original: data.user.settings.displayName
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-label' />
              <div className='field-body is-align-items-center'>
                <div className='field is-narrow'>
                  <p className='help'>{locale.profile.display_name_hint}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='box'>
            <h2 className='subtitle is-5 has-text-weight-medium'>
              {locale.viewer_settings.title}
            </h2>
            <hr />
            <div className='field is-horizontal'>
              <div className='field-label is-flex is-align-items-center'>
                <label className='label'>
                  {locale.viewer_settings.default_zoom}
                </label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <div className='select'>
                      <select
                        value={
                          typeof settings.viewerDefaultPaperSpeed ===
                          'undefined'
                            ? data.user.settings.viewerDefaultPaperSpeed || ''
                            : settings.viewerDefaultPaperSpeed
                        }
                        onChange={(e) =>
                          dispatchSettings({
                            key: 'viewerDefaultPaperSpeed',
                            value: e.target.value,
                            original: data.user.settings.viewerDefaultPaperSpeed
                          })
                        }
                      >
                        <option value='PaperSpeed_5'>5</option>
                        <option value='PaperSpeed_10'>10</option>
                        <option value='PaperSpeed_25'>25</option>
                        <option value='PaperSpeed_50'>50</option>
                        <option value='PaperSpeed_100'>100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-label is-flex is-align-items-center'>
                <label className='label'>
                  {locale.viewer_settings.default_amplitude}
                </label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <div className='select'>
                      <select
                        value={
                          typeof settings.viewerDefaultAmplitude === 'undefined'
                            ? data.user.settings.viewerDefaultAmplitude || ''
                            : settings.viewerDefaultAmplitude
                        }
                        onChange={(e) =>
                          dispatchSettings({
                            key: 'viewerDefaultAmplitude',
                            value: e.target.value,
                            original: data.user.settings.viewerDefaultAmplitude
                          })
                        }
                      >
                        <option value='Amplitude_2'>2</option>
                        <option value='Amplitude_5'>5</option>
                        <option value='Amplitude_10'>10</option>
                        <option value='Amplitude_20'>20</option>
                        <option value='Amplitude_40'>40</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-label is-flex is-align-items-center'>
                <label className='label'>
                  {locale.viewer_settings.default_qrs_intervals}
                </label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <div className='select'>
                      <select
                        value={
                          typeof settings.viewerDefaultQRSIntervals ===
                          'undefined'
                            ? data.user.settings.viewerDefaultQRSIntervals || ''
                            : settings.viewerDefaultQRSIntervals
                        }
                        onChange={(e) =>
                          dispatchSettings({
                            key: 'viewerDefaultQRSIntervals',
                            value: e.target.value,
                            original:
                              data.user.settings.viewerDefaultQRSIntervals
                          })
                        }
                      >
                        <option value='RRintervals'>
                          {locale.viewer_settings.qrs_intervals.rr}
                        </option>
                        <option value='HeartRate'>
                          {locale.viewer_settings.qrs_intervals.hr}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-label is-flex is-align-items-center'>
                <label className='label'>
                  {locale.viewer_settings.default_grid}
                </label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <div className='select'>
                      <select
                        value={
                          typeof settings.viewerGridType === 'undefined'
                            ? data.user.settings.viewerGridType || ''
                            : settings.viewerGridType
                        }
                        onChange={(e) =>
                          dispatchSettings({
                            key: 'viewerGridType',
                            value: e.target.value,
                            original: data.user.settings.viewerGridType
                          })
                        }
                      >
                        <option value='GridType_5X5'>5x5</option>
                        <option value='GridType_10X10'>10x10</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-label is-flex is-align-items-center'>
                <label className='label'>
                  {locale.profile.display_predictive_measurement}
                </label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        checked={
                          typeof settings.viewerDisplayPredictiveMeasurement ===
                          'undefined'
                            ? data.user.settings
                                .viewerDisplayPredictiveMeasurement
                            : settings.viewerDisplayPredictiveMeasurement
                        }
                        onChange={(e) =>
                          dispatchSettings({
                            key: 'viewerDisplayPredictiveMeasurement',
                            value: e.target.checked,
                            original:
                              data.user.settings
                                .viewerDisplayPredictiveMeasurement
                          })
                        }
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='box'>
            <h2 className='subtitle is-5 has-text-weight-medium'>
              {locale.report_settings.title}
            </h2>
            <hr />
            <div className='field is-horizontal'>
              <div className='field-label is-flex is-align-items-center'>
                <label className='label'>{locale.profile.paper_size}</label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <label className='radio'>
                      <input
                        type='radio'
                        name='reportPaperSize'
                        value='A4'
                        checked={
                          (settings.reportPaperSize ||
                            data.user.settings.reportPaperSize) === 'A4'
                        }
                        onChange={(e) =>
                          dispatchSettings({
                            key: 'reportPaperSize',
                            value: e.target.value,
                            original: data.user.settings.reportPaperSize
                          })
                        }
                      />
                      &nbsp; {locale.profile.a4}
                    </label>
                    <label className='radio'>
                      <input
                        type='radio'
                        name='reportPaperSize'
                        value='Letter'
                        checked={
                          (settings.reportPaperSize ||
                            data.user.settings.reportPaperSize) === 'Letter'
                        }
                        onChange={(e) =>
                          dispatchSettings({
                            key: 'reportPaperSize',
                            value: e.target.value,
                            original: data.user.settings.reportPaperSize
                          })
                        }
                      />
                      &nbsp; {locale.profile.letter}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-label is-flex is-align-items-center'>
                <label className='label'>{locale.report_settings.header}</label>
              </div>
              <div className='field-body'>
                <div className='field is-narrow'>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      value={
                        typeof settings.reportHeader === 'undefined'
                          ? data.user.settings.reportHeader || ''
                          : settings.reportHeader
                      }
                      placeholder={
                        typeof settings.displayName === 'undefined'
                          ? data.user.settings.displayName || defaultDisplayName
                          : settings.displayName || defaultDisplayName
                      }
                      onChange={(e) =>
                        dispatchSettings({
                          key: 'reportHeader',
                          value: e.target.value,
                          original: data.user.settings.reportHeader
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-label is-flex'>
                <label className='label'>
                  {locale.report_settings.conclusion_templates}
                </label>
              </div>
              <div className='field-body'>
                <div className='field is-narrow'>
                  <nav
                    className={clsx('panel conclusion-templates', {
                      'has-templates':
                        Object.keys(reportConclusionTemplates).length > 0
                    })}
                  >
                    {Object.keys(reportConclusionTemplates).map((key) => (
                      <a
                        key={key}
                        className='panel-block is-flex is-justify-content-space-between hover-container'
                        onClick={() => setConclusionTemplateId(key)}
                      >
                        <span className='report-conclusion-template-name'>
                          {reportConclusionTemplates[key].name}
                        </span>
                        <div className='buttons'>
                          <span
                            className='is-text hover pl-0 mr-2'
                            onClick={(e) => {
                              e.stopPropagation()
                              setConclusionTemplateId(key)
                            }}
                          >
                            <span className='icon'>
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </span>
                          </span>
                          <span
                            className='is-text hover pl-0 mr-2'
                            onClick={(e) => {
                              e.stopPropagation()
                              dispatchReportConclusionTemplates({
                                key,
                                value: 'DELETE',
                                original: 'DELETE'
                              })

                              if (defaultConclusionTemplate === key) {
                                setDefaultConclusionTemplate('')
                              }
                            }}
                          >
                            <span className='icon'>
                              <FontAwesomeIcon icon={faTrash} />
                            </span>
                          </span>
                          <span
                            className={clsx('is-text hover pl-0', {
                              'is-visible': defaultConclusionTemplate === key
                            })}
                            onClick={(e) => {
                              e.stopPropagation()
                              if (defaultConclusionTemplate === key) {
                                setDefaultConclusionTemplate('')
                                return
                              }
                              setDefaultConclusionTemplate(key)
                            }}
                          >
                            <span className='icon'>
                              <FontAwesomeIcon
                                icon={
                                  defaultConclusionTemplate === key
                                    ? faStarSolid
                                    : faStarRegular
                                }
                              />
                            </span>
                          </span>
                        </div>
                      </a>
                    ))}
                    <div className='panel-block'>
                      <button
                        className='button is-link is-outlined is-fullwidth'
                        onClick={() => setConclusionTemplateId('new')}
                      >
                        {locale.report_settings.add}
                      </button>
                    </div>
                  </nav>
                  <div
                    className={clsx('help is-danger', {
                      'is-hidden': !hasConclusionTemplateUpdated
                    })}
                  >
                    {locale.report_settings.save_warning}
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-label is-flex is-align-items-center'>
                <label className='label'>
                  {locale.report_settings.signature}
                </label>
              </div>
              <div className='field-body'>
                <div className='field is-narrow'>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      value={
                        typeof settings.reportSignature === 'undefined'
                          ? data.user.settings.reportSignature || ''
                          : settings.reportSignature
                      }
                      placeholder={
                        typeof settings.displayName === 'undefined'
                          ? data.user.settings.displayName || defaultDisplayName
                          : settings.displayName || defaultDisplayName
                      }
                      onChange={(e) =>
                        dispatchSettings({
                          key: 'reportSignature',
                          value: e.target.value,
                          original: data.user.settings.reportSignature
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div className='field-label is-flex is-align-items-center'>
                <label className='label'>
                  {locale.report_settings.digital_signature}
                </label>
              </div>
              <div className='field-body'>
                <div className='field is-narrow'>
                  <div className='control'>
                    <div id='digital_signature_section'>
                      <div>
                        <button
                          className='button is-outlined is-primary'
                          onClick={() => setDigitalSignatureEditMode(true)}
                        >
                          {locale.report_settings.update_digital_signature}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='is-flex is-align-items-center'>
            <button
              className={clsx('button is-primary', {
                'is-loading': updateSettingsLoading
              })}
              disabled={!(hasNewSettingsData || hasConclusionTemplateUpdated)}
              onClick={() => {
                let input = {
                  settings: settings,
                  updatedAt: new Date().toISOString()
                }

                if (hasConclusionTemplateUpdated) {
                  input.settings.reportConclusionTemplates = JSON.stringify({
                    list: reportConclusionTemplates,
                    default_template: reportConclusionTemplates[
                      defaultConclusionTemplate
                    ]
                      ? defaultConclusionTemplate
                      : ''
                  })
                }

                removeEmptyObjects(input)

                updateSettings({
                  variables: {
                    input: {
                      id,
                      ...input
                    }
                  },
                  update: (cache) => {
                    cache.modify({
                      id: cache.identify(data.user),
                      fields: objectToFieldsConverter(input)
                    })
                  }
                })
              }}
            >
              {locale.report_settings.submit}
            </button>
            &nbsp;
            <span
              className={clsx('help is-primary', {
                'is-hidden': !isSettingsUpdated
              })}
            >
              {locale.report_settings.settings_updated}
            </span>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Settings
