if (typeof window !== 'undefined') {

MeasurementValuesManager = {};
// MeasurementValuesManager.getValues = function() {
//     var measurementValues = {
//         HR: $('#hr_measurement').val(),
//         P: $('#p_measurement').val(),
//         P_MV: $('#p_mv_measurement').val(),
//         PR: $('#pr_measurement').val(),
//         QRS: $('#qrs_measurement').val(),
//         R: $('#r_measurement').val(),
//         QT:$('#qt_measurement').val()
//     };
//     var meaLabel = $('#mea_label').val();

//     return {
//         values: measurementValues,
//         meaLabel: meaLabel
//     };
// };

MeasurementValuesManager.getMeaLabel = function(qrsAngle) {
    var locale = bcGlobals.studySummaryLocale;
    angle = (qrsAngle === locale.noValue) ? 0 : qrsAngle;

    // qrsAngle value can be negative... so map it to 0-360 range
    qrsAngle = angle % 360;
    if (qrsAngle < 0) {
        qrsAngle += 360;
    }

    var meaLabelValue = null;
    if (bcGlobals.isAnimal) {
        if (bcGlobals.animalType === bcGlobals.animalTypesEnum.BIG_DOG ||
            bcGlobals.animalType === bcGlobals.animalTypesEnum.MEDIUM_DOG ||
            bcGlobals.animalType === bcGlobals.animalTypesEnum.SMALL_DOG ||
            bcGlobals.animalType === bcGlobals.animalTypesEnum.DOG) {
                if (qrsAngle >= 40 && qrsAngle <= 100) {
                    meaLabelValue = 'Normal';
                } else if (qrsAngle > 100 && qrsAngle <= 135) {
                    meaLabelValue = 'Mild';
                } else if (qrsAngle > 135 && qrsAngle <= 180) {
                    meaLabelValue = 'Modarate';
                } else if (qrsAngle > 180 && qrsAngle <= 270) {
                    meaLabelValue = 'Severe';
                } else {
                    meaLabelValue = 'Left';
                }
        } else if (bcGlobals.animalType === bcGlobals.animalTypesEnum.CAT) {
            if (qrsAngle >= 0 && qrsAngle <= 160) {
                meaLabelValue = 'Normal';
            } else if (qrsAngle > 160 && qrsAngle <= 270) {
                meaLabelValue = 'Right';
            } else {
                meaLabelValue = 'Left';
            }
        }
    }
    return meaLabelValue;
};

window.MeasurementValuesManager = MeasurementValuesManager

}
